import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  Col,
  Row
} from "reactstrap"

import { Image, ImageMetaSpecies } from "../../../interfaces"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import { toPascalCase } from "../../../utility/helpers"
import SpeciesInputSelect from "../../components/inputs/SpeciesInputSelect"
import InputText from "../../components/InputText"
import {
  StatusCode
} from "../../../networking"
import { AxiosResponse } from "axios"
import { Language } from "../../../language"
import { toast } from "react-hot-toast"
import { createSpecies, updateImageMetaSpecies } from "../../../networking/api"

interface Props {
  isModalOpen: boolean
  onModalClose: any
  image: Image | undefined
  onUpdate: any
}

enum Intention {
  INITIAL = "initial",
  REJECT = "reject",
  REJECT_CUSTOM = "reject_custom"
}

const EditSpeciesModal: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [image, setImage] = useState<Image | undefined>(undefined)
  const [imageMetaSpecies, setImageMetaSpecies] = useState<
    ImageMetaSpecies | undefined
  >(undefined)

  const [intention, setIntention] = useState<Intention>(Intention.INITIAL)
  const [selectedSpecies, setSelectedSpecies] = useState<string | undefined>(
    undefined
  )
  const [newSpecies, setNewSpecies] = useState<string | undefined>(undefined)

  // on props update
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)
    setImage(props.image)

    // get image location data
    if (props.image && props.image.species[0]) {
      let imageMetaSpecies = props.image.species[0]

      setImageMetaSpecies(imageMetaSpecies)
    }
  }, [props])

  const onApproveSpeciesPressed = () => {
    if (sessionToken && imageMetaSpecies && imageMetaSpecies.species) {
      updateImageMetaSpecies(
        sessionToken,
        imageMetaSpecies.id,
        imageMetaSpecies.species
      ).then(response => {
        if (response && response.status === StatusCode.OK) {
          props.onUpdate()
        }
      })
    }

    onClosePressed()
  }

  const onNewSpeciesSelected = (species: string) => {
    if (sessionToken && imageMetaSpecies && species) {
      updateImageMetaSpecies(sessionToken, imageMetaSpecies.id, species).then(
        response => {
          if (response && response.status === StatusCode.OK) {
            toast.success(Language.SUCCESS_IMAGE_SPECIES_UPDATED)
            props.onUpdate()
          }
        }
      )
    }
  }

  const onCreateNewSpeciesPressed = () => {
    if (imageMetaSpecies && newSpecies) {
      const lowercaseSpecies = newSpecies.toLocaleLowerCase()

      let promises: Array<Promise<AxiosResponse | undefined>> = []

      promises.push(
        createSpecies(sessionToken, lowercaseSpecies),
        updateImageMetaSpecies(
          sessionToken,
          imageMetaSpecies.id,
          lowercaseSpecies
        )
      )

      Promise.all(promises).then(() => {
        onClosePressed()
        props.onUpdate()
      })
    }
  }

  const onClosePressed = () => {
    setIntention(Intention.INITIAL)
    setSelectedSpecies(undefined)
    setNewSpecies(undefined)
    props.onModalClose()
  }

  let modalTitle = ""
  if (imageMetaSpecies && intention === Intention.INITIAL) {
    modalTitle = toPascalCase(imageMetaSpecies.species)
  } else if (imageMetaSpecies && intention === Intention.REJECT) {
    modalTitle = "Adjust species"
  } else if (imageMetaSpecies && intention === Intention.REJECT_CUSTOM) {
    modalTitle = "Add new species"
  }

  return (
    <Modal isOpen={isModalOpen} contentClassName={""} centered>
      <ModalHeader>
        <div className="mb-0">{Language.MT_EDIT_SPECIES}</div>
        <small>{Language.MS_EDIT_SPECIES}</small>
      </ModalHeader>
      <ModalBody className="text-center">
        <h4>{modalTitle}</h4>

        {intention === Intention.INITIAL && (
          <div>
            <Button
              className="mx-2 text-white"
              color="success"
              onClick={onApproveSpeciesPressed}
            >
              Approve species
            </Button>
            <Button
              className="mx-2 text-white"
              color="danger"
              onClick={() => setIntention(Intention.REJECT)}
            >
              Reject species
            </Button>
          </div>
        )}

        {intention === Intention.REJECT && (
          <Row>
            <Col lg={8}>
              <SpeciesInputSelect
                message={"Select the correct species..."}
                selectedSpecies={selectedSpecies}
                onSelect={onNewSpeciesSelected}
              />
            </Col>
            <Col lg={4}>
              <Button
                className={"container-fluid"}
                onClick={() => setIntention(Intention.REJECT_CUSTOM)}
                color={"primary"}
                size={"sm"}
              >
                Or create new species
              </Button>
            </Col>
          </Row>
        )}

        {intention === Intention.REJECT_CUSTOM && (
          <Row>
            <Col lg={8}>
              <InputText
                type="text"
                onChange={event => {
                  setNewSpecies(event.target.value)
                }}
                control={{
                  id: "species",
                  name: "species",
                  placeholder: "Enter new species name",
                  value: newSpecies
                }}
              />
            </Col>
            <Col lg={4}>
              <Button
                className={"container-fluid"}
                onClick={onCreateNewSpeciesPressed}
                color={"primary"}
                size={"sm"}
                disabled={!newSpecies}
              >
                Add and set species
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="text-white"
            color="secondary"
            onClick={onClosePressed}
          >
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default EditSpeciesModal
