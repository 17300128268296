import { ImageFilter, LbsprParameterGroup } from "../interfaces"

export const defaultLbsprParameterGroup: LbsprParameterGroup = {
  name: "",
  species: "",
  l_inf: 0,
  l_50: 0,
  l_95: 0,
  mk: 0,
  bin_width: 0,
  bin_min: 0,
  bin_max: 0,
  is_default: false
}

export const defaultImageFilter: ImageFilter = {
  species: undefined,
  country: undefined,
  user: undefined
}
