import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"

import ListView from "../components/ListView"

import { StatusCode } from "../../networking"
import { useGlobalState } from "../.."
import { GlobalStateKey } from "../../utility/globalStateKey"
import { UserProfile } from "../../interfaces"
import Avatar from "../components/Avatar"
import Badge from "../components/Badge"
import CreateUserModal from "./modals/CreateUserModal"
import moment from "moment"
import RowResetButton from "../components/buttons/RowResetButton"
import { Language } from "../../language"
import { getUserReset, getUsers } from "../../networking/api"

const Users: React.FC = () => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )
  const [isLoading, setIsLoading] = useGlobalState(GlobalStateKey.IS_LOADING)

  // local state
  const [users, setUsers] = useState<Array<UserProfile>>()
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] =
    useState<boolean>(false)
  const [modifiedAt, setModifiedAt] = useState<number | undefined>(undefined)

  // on load
  useEffect(() => {
    setIsLoading(true)

    getUsers(sessionToken)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          setUsers(response.data.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [modifiedAt])

  const headers = [
    { caption: "", field: "avatar", classes: "text-center align-middle" },
    { caption: "Email", field: "email", classes: "text-left align-middle" },
    {
      caption: "First Name (WIP)",
      field: "first_name",
      classes: "text-left align-middle"
    },
    {
      caption: "Last Name (WIP)",
      field: "last_name",
      classes: "text-left align-middle"
    },
    { caption: "Groups", field: "groups", classes: "align-middle" },
    { caption: "Actions", field: "actions", classes: "align-middle" }
  ]

  const renderHeader = headers => {
    return headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ))
  }

  const renderRow = (headers, row) => {
    const groups = row.user_groups.map((user_group, index) => {
      const group = user_group.group
      return (
        <Badge key={index} className={"bg-danger"}>
          {group.name.toUpperCase()}
        </Badge>
      )
    })

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case "avatar":
          return (
            <td key={index} className={header.classes}>
              <Avatar user={row} />
            </td>
          )
        case "first_name":
          return (
            <td key={index} className={header.classes}>
              N/A
            </td>
          )
        case "last_name":
          return (
            <td key={index} className={header.classes}>
              N/A
            </td>
          )
        case "groups":
          return (
            <td key={index} className={header.classes}>
              {groups}
            </td>
          )
        case "actions":
          return (
            <td key={index} className={header.classes}>
              <RowResetButton
                onClick={() => {
                  onResetPasswordPressed(row)
                }}
              />
            </td>
          )
        default:
          return (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          )
      }
    })
    return tableTd
  }

  const onUsersUpdate = () => {
    setModifiedAt(moment().unix())
  }

  const onCreateUserPressed = () => {
    setIsCreateUserModalOpen(true)
  }

  const onCreateUserModalClosed = () => {
    setIsCreateUserModalOpen(false)
  }

  const onResetPasswordPressed = (user: UserProfile) => {
    getUserReset(sessionToken, user.id).then(response => {
      if (response && response.status === StatusCode.OK) {
        alert(
          `Get the user to visit jcufish.com.au/reset/${response.data.data} to reset their password!`
        )
      }
    })
  }

  let bodyRows
  if (users && users.length > 0) {
    bodyRows = users.map((row, index) => (
      <tr key={index}>{renderRow(headers, row)}</tr>
    ))
  }

  const iconName = "users"

  return (
    <div className="px-3 pt-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row">
          <h3>Users</h3>
        </div>
      </div>

      {/* Modals */}
      <CreateUserModal
        isModalOpen={isCreateUserModalOpen}
        onModalClose={onCreateUserModalClosed}
        onUpdate={onUsersUpdate}
      />

      <Row className={"pt-4"}>
        <Col md={2}>
          <Button size="sm" color="primary" onClick={onCreateUserPressed}>
            Create user
          </Button>
        </Col>
      </Row>

      <ListView
        header={renderHeader(headers)}
        bodyRows={bodyRows}
        rows={users || []}
        iconName={iconName}
        emptyCaption={Language.I_NO_USERS_AVAILABLE}
      />
    </div>
  )
}

export default Users
