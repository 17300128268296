import { AxiosResponse } from "axios"
import {
  Api,
  makeRequest,
  RequestMethod,
  RequestOptions,
  RequestResponseType
} from "."
import { LbsprParameterGroup } from "../interfaces"
import { ModelEstimatesResponse } from "../interfaces/lbspr"
import { SessionToken } from "../types"

export const LBSPR_ENDPOINT = 'https://lbspr.jcufish.com.au'

export interface LbsprBodyParameters {
  Species: string
  MK: number
  Linf: number
  L_units: string
  L50: number
  L95: number
  FM?: number
  SPR?: number
  BinWidth: number
  BinMin?: number
  BinMax?: number
}

export interface LbsprRequest {
  parameters: LbsprParameterGroup
  lengthData: object
}

function makeRequestBody(lbsprRequest: LbsprRequest) {
  return {
    parameters: mapParameterGroup(lbsprRequest.parameters),
    lengths: lbsprRequest.lengthData
  }
}

function mapParameterGroup(
  lbsprParameterGroup: LbsprParameterGroup
): LbsprBodyParameters {
  let parameters: LbsprBodyParameters = {
    Species: lbsprParameterGroup.species,
    MK: lbsprParameterGroup.mk,
    Linf: lbsprParameterGroup.l_inf,
    L_units: "cm",
    L50: lbsprParameterGroup.l_50,
    L95: lbsprParameterGroup.l_95,
    BinWidth: lbsprParameterGroup.bin_width
  }

  if (lbsprParameterGroup.bin_min)
    parameters.BinMin = lbsprParameterGroup.bin_min

  if (lbsprParameterGroup.bin_max)
    parameters.BinMax = lbsprParameterGroup.bin_max

  return parameters
}

export function plotHistogram(
  sessionToken: SessionToken,
  lbsprRequest: LbsprRequest
) {
  const requestOptions: RequestOptions = {
    api: Api.LBSPR,
    url: `${LBSPR_ENDPOINT}/plot-histogram`,
    method: RequestMethod.POST,
    sessionToken: sessionToken,
    responseType: RequestResponseType.BLOB,
    body: makeRequestBody(lbsprRequest)
  }

  return makeRequest(requestOptions)
}

export function fitAndPlotHistogram(
  sessionToken: SessionToken,
  lbsprRequest: LbsprRequest
) {
  const requestOptions: RequestOptions = {
    api: Api.LBSPR,
    url: `${LBSPR_ENDPOINT}/fit-and-plot-histogram`,
    method: RequestMethod.POST,
    sessionToken: sessionToken,
    responseType: RequestResponseType.BLOB,
    body: makeRequestBody(lbsprRequest)
  }

  return makeRequest(requestOptions)
}

export function plotEstimates(
  sessionToken: SessionToken,
  lbsprRequest: LbsprRequest
) {
  const requestOptions: RequestOptions = {
    api: Api.LBSPR,
    url: `${LBSPR_ENDPOINT}/plot-estimates`,
    method: RequestMethod.POST,
    sessionToken: sessionToken,
    responseType: RequestResponseType.BLOB,
    body: makeRequestBody(lbsprRequest)
  }

  return makeRequest(requestOptions)
}

export function plotSprCircle(
  sessionToken: SessionToken,
  lbsprRequest: LbsprRequest
) {
  const requestOptions: RequestOptions = {
    api: Api.LBSPR,
    url: `${LBSPR_ENDPOINT}/plot-spr-circle`,
    method: RequestMethod.POST,
    sessionToken: sessionToken,
    responseType: RequestResponseType.BLOB,
    body: makeRequestBody(lbsprRequest)
  }

  return makeRequest(requestOptions)
}

export function modelEstimates(
  sessionToken: SessionToken,
  lbsprRequest: LbsprRequest
): Promise<AxiosResponse<ModelEstimatesResponse> | undefined> {
  const requestOptions: RequestOptions = {
    api: Api.LBSPR,
    url: `${LBSPR_ENDPOINT}/model-estimates`,
    method: RequestMethod.POST,
    sessionToken: sessionToken,
    responseType: RequestResponseType.NORMAL,
    body: makeRequestBody(lbsprRequest)
  }

  return makeRequest(requestOptions)
}
