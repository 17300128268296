import { nanoid } from "nanoid"
import React, { useState } from "react"
import { Tooltip } from "reactstrap"
import { Language } from "../../../language"

interface Props {
  onClick: any
}

const RowEditButton: React.FC<Props> = props => {
  // local state
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipId, setTooltipId] = useState(nanoid())

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  return (
    <React.Fragment>
      <span
        className="icon icon-pencil p-3 text-dark"
        style={{ cursor: "pointer" }}
        onClick={props.onClick}
        id={`button-${tooltipId}`}
      />
      <Tooltip
        placement="top"
        target={`button-${tooltipId}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
      >
        {Language.TT_EDIT}
      </Tooltip>
    </React.Fragment>
  )
}

export default RowEditButton
