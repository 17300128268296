interface MainMenuItem {
  url: string
  caption: string
  iconName: string
  authTag?: string | undefined
}

export const mainMenuItems: Array<MainMenuItem> = [
  {
    url: "/images",
    caption: "Images",
    iconName: "table"
  },
  {
    url: "/quality_controls",
    caption: "Quality Control",
    iconName: "eye"
  },
  {
    url: "/lbspr_parameter_groups",
    caption: "Parameter Groups",
    iconName: "folder",
    authTag: "admin"
  },
  {
    url: "/users",
    caption: "Users",
    iconName: "users",
    authTag: "admin"
  },
  {
    url: "/logout",
    caption: "Logout",
    iconName: "exit"
  }
]
