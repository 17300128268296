import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"

import ListView from "../components/ListView"

import { StatusCode } from "../../networking"
import { useGlobalState } from "../.."
import { GlobalStateKey } from "../../utility/globalStateKey"
import { LbsprParameterGroup } from "../../interfaces"
import Avatar from "../components/Avatar"
import moment from "moment"
import LbsprParameterGroupModal from "./modals/LbsprParameterGroupModal"
import RowEditButton from "../components/buttons/RowEditButton"
import RowDeleteButton from "../components/buttons/RowDeleteButton"
import { toPascalCase } from "../../utility/helpers"
import { Language } from "../../language"
import { toast } from "react-hot-toast"
import {
  defaultLbsprParameterGroup,
  deleteLbsprParameterGroup,
  getLbsprParameterGroups
} from "../../networking/api"

const LbsprParameterGroups: React.FC = () => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )
  const [isLoading, setIsLoading] = useGlobalState(GlobalStateKey.IS_LOADING)

  // local state
  const [lbsprParameterGroups, setLbsprParameterGroups] =
    useState<Array<LbsprParameterGroup>>()
  const [lbsprParameterGroup, setLbsprParameterGroup] = useState<
    LbsprParameterGroup | undefined
  >()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [modifiedAt, setModifiedAt] = useState<number | undefined>(undefined)

  // on load
  useEffect(() => {
    setIsLoading(true)

    getLbsprParameterGroups(sessionToken)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          setLbsprParameterGroups(response.data.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [modifiedAt])

  const headers = [
    { caption: "Name", field: "name", classes: "text-left align-middle" },
    { caption: "Species", field: "species", classes: "text-left align-middle" },
    { caption: "Country", field: "country", classes: "text-left align-middle" },
    { caption: "L Inf", field: "l_inf", classes: "text-left align-middle" },
    { caption: "L 50", field: "l_50", classes: "text-left align-middle" },
    { caption: "L 95", field: "l_95", classes: "text-left align-middle" },
    { caption: "MK", field: "mk", classes: "text-left align-middle" },
    {
      caption: "Bin Width",
      field: "bin_width",
      classes: "text-left align-middle"
    },
    { caption: "Bin Min", field: "bin_min", classes: "text-left align-middle" },
    { caption: "Bin Max", field: "bin_max", classes: "text-left align-middle" },
    {
      caption: "Default",
      field: "is_default",
      classes: "text-center align-middle"
    },
    {
      caption: "Created By",
      field: "avatar",
      classes: "text-center align-middle"
    },
    {
      caption: "Actions",
      field: "actions",
      classes: "text-center align-middle"
    }
  ]

  const renderHeader = headers => {
    return headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ))
  }

  const renderRow = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case "country":
          return (
            <td key={index} className={header.classes}>
              {row.country ? toPascalCase(row.country) : "N/A"}
            </td>
          )
        case "is_default":
          return (
            <td key={index} className={header.classes}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => onDefaultClicked(row)}
              >
                {row.is_default ? (
                  <span className={"icon icon-checkbox-checked text-primary"} />
                ) : (
                  <span
                    className={"icon icon-checkbox-unchecked text-primary"}
                  />
                )}
              </div>
            </td>
          )
        case "avatar":
          return (
            <td key={index} className={header.classes}>
              <Avatar user={row.createdby} />
            </td>
          )
        case "actions":
          return (
            <td key={index} className={header.classes}>
              <RowEditButton onClick={() => onEditClicked(row)} />
              <RowDeleteButton onClick={() => onDeleteClicked(row)} />
            </td>
          )
        case "species":
          return (
            <td key={index} className={header.classes}>
              {toPascalCase(row.species)}
            </td>
          )
        default:
          return (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          )
      }
    })
    return tableTd
  }

  const onParameterGroupUpdate = () => {
    setModifiedAt(moment().unix())
  }

  const onCreateUserPressed = () => {
    setIsCreateModalOpen(true)
  }

  const onCreateModalClosed = () => {
    setIsCreateModalOpen(false)
    setLbsprParameterGroup(undefined)
  }

  const onEditClicked = (lbsprParameterGroup: LbsprParameterGroup) => {
    setLbsprParameterGroup(lbsprParameterGroup)
    setIsCreateModalOpen(true)
  }

  const onDeleteClicked = (lbsprParameterGroup: LbsprParameterGroup) => {
    if (lbsprParameterGroup.id) {
      deleteLbsprParameterGroup(sessionToken, lbsprParameterGroup.id).then(
        response => {
          if (response && response.status === StatusCode.OK) {
            toast.success(Language.SUCCESS_LBSPR_PARAMETER_GROUP_DELETED)
            setModifiedAt(moment().unix())
          }
        }
      )
    }
  }

  const onDefaultClicked = (lbsprParameterGroup: LbsprParameterGroup) => {
    defaultLbsprParameterGroup(
      sessionToken,
      lbsprParameterGroup,
      !lbsprParameterGroup.is_default
    ).then(response => {
      if (response && response.status === StatusCode.OK) {
        toast.success(Language.SUCCESS_LBSPR_PARAMETER_GROUP_UPDATED)
        setModifiedAt(moment().unix())
      }
    })
  }

  let bodyRows
  if (lbsprParameterGroups && lbsprParameterGroups.length > 0) {
    bodyRows = lbsprParameterGroups.map((row, index) => (
      <tr key={index}>{renderRow(headers, row)}</tr>
    ))
  }

  const iconName = "folder"

  return (
    <div className="px-3 pt-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row">
          <h3>LBSPR Parameter Groups</h3>
        </div>
      </div>

      {/* Modals */}
      <LbsprParameterGroupModal
        lbsprParameterGroup={lbsprParameterGroup}
        isModalOpen={isCreateModalOpen}
        onModalClose={onCreateModalClosed}
        onUpdate={onParameterGroupUpdate}
      />

      <Row className={"pt-4"}>
        <Col md={2}>
          <Button size="sm" color="primary" onClick={onCreateUserPressed}>
            Create parameter group
          </Button>
        </Col>
      </Row>

      <ListView
        header={renderHeader(headers)}
        bodyRows={bodyRows}
        rows={lbsprParameterGroups || []}
        iconName={iconName}
        emptyCaption={Language.I_NO_LBSPR_PARAMETER_GROUPS_AVAILABLE}
      />
    </div>
  )
}

export default LbsprParameterGroups
