import { useEffect, useState } from "react"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import InputText from "../../components/InputText"
import { LbsprParameterGroup } from "../../../interfaces"
import { ResourceIntention } from "../../../types"
import { toPascalCase } from "../../../utility/helpers"
import { defaultLbsprParameterGroup } from "../../../defaults"
import { StatusCode } from "../../../networking"
import { Language } from "../../../language"
import { toast } from "react-hot-toast"
import CountryInputSelect from "../../components/inputs/CountryInputSelect"
import {
  addLbsprParameterGroup,
  updateLbsprParameterGroup
} from "../../../networking/api"
import Box from "../../components/Box"
import SpeciesInputSelect from "../../components/inputs/SpeciesInputSelect"

interface Props {
  lbsprParameterGroup: LbsprParameterGroup | undefined
  isModalOpen: boolean
  onModalClose: any
  onUpdate: any
}

const LbsprParameterGroupModal: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [intention, setIntention] = useState<ResourceIntention>()
  const [lbsprParameterGroup, setLbsprParameterGroup] =
    useState<LbsprParameterGroup>({ ...defaultLbsprParameterGroup })

  // on props update
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)

    if (props.lbsprParameterGroup) {
      setIntention(ResourceIntention.UPDATE)
      setLbsprParameterGroup(props.lbsprParameterGroup)
    } else {
      setIntention(ResourceIntention.CREATE)
      setLbsprParameterGroup({ ...defaultLbsprParameterGroup })
    }
  }, [props])

  const onActionButtonPressed = () => {
    if (intention === ResourceIntention.CREATE) {
      // create
      addLbsprParameterGroup(sessionToken, lbsprParameterGroup).then(
        response => {
          if (response && response.status === StatusCode.OK) {
            toast.success(Language.SUCCESS_LBSPR_PARAMETER_GROUP_CREATED)
            props.onUpdate()
            onCloseButtonPressed()
          }
        }
      )
    } else {
      // edit
      updateLbsprParameterGroup(sessionToken, lbsprParameterGroup).then(
        response => {
          if (response && response.status === StatusCode.OK) {
            toast.success(Language.SUCCESS_LBSPR_PARAMETER_GROUP_UPDATED)
            props.onUpdate()
            onCloseButtonPressed()
          }
        }
      )
    }
  }

  const onCloseButtonPressed = () => {
    setLbsprParameterGroup({ ...lbsprParameterGroup })
    props.onModalClose()
  }

  const modalTitle =
    intention === ResourceIntention.CREATE
      ? Language.MT_CREATE_LBSPR_PARAMETER_GROUP
      : Language.MT_EDIT_LBSPR_PARAMETER_GROUP

  const modalDescription =
    intention === ResourceIntention.CREATE
      ? Language.MS_CREATE_LBSPR_PARAMETER_GROUP
      : Language.MS_EDIT_LBSPR_PARAMETER_GROUP

  return (
    <Modal isOpen={isModalOpen} contentClassName={""} centered>
      <ModalHeader>
        <div className="mb-0">{modalTitle}</div>
        <small>{modalDescription}</small>
      </ModalHeader>
      <ModalBody>
        <div>
          <Box title="Details:">
            <Row>
              <Col md={4}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      name: event.target.value.toLowerCase()
                    })
                  }}
                  control={{
                    id: "name",
                    name: "name",
                    caption: "Name",
                    placeholder: "Name",
                    value: lbsprParameterGroup.name
                  }}
                  inputHelp={{
                    title: "(More info)",
                    message: "A name to help identify the parameter group"
                  }}
                />
              </Col>
              <Col md={4}>
                <SpeciesInputSelect
                  onSelect={species => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      species: species
                    })
                  }}
                  message="Select a species..."
                  selectedSpecies={lbsprParameterGroup.species}
                  control={{
                    id: "species",
                    name: "species",
                    caption: "Species",
                    value: lbsprParameterGroup.species
                  }}
                />
              </Col>
              <Col md={4}>
                <CountryInputSelect
                  onSelect={country =>
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      country: country
                    })
                  }
                  selectMessage="Select a country..."
                  control={{
                    id: "country",
                    name: "country",
                    caption: "Country",
                    value: lbsprParameterGroup.country
                  }}
                />
              </Col>
            </Row>
          </Box>
        </div>
        <div className="mt-4">
          <Box title="Biology Parameters:">
            <Row>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      l_inf: event.target.value
                    })
                  }}
                  control={{
                    id: "linf",
                    name: "L inf",
                    caption: "L inf",
                    placeholder: "L inf",
                    value: lbsprParameterGroup.l_inf
                  }}
                  inputHelp={{
                    title: "(More info)",
                    message: "Von Bertalanffy asymptotic length"
                  }}
                />
              </Col>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      l_50: event.target.value
                    })
                  }}
                  control={{
                    id: "l50",
                    name: "l50",
                    caption: "L 50",
                    placeholder: "L 50",
                    value: lbsprParameterGroup.l_50
                  }}
                  inputHelp={{
                    title: "(More info)",
                    message: "Length at 50% maturity"
                  }}
                />
              </Col>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      l_95: event.target.value
                    })
                  }}
                  control={{
                    id: "l95",
                    name: "l95",
                    caption: "L 95",
                    placeholder: "L 95",
                    value: lbsprParameterGroup.l_95
                  }}
                  inputHelp={{
                    title: "(More info)",
                    message: "Length at 95% maturity"
                  }}
                />
              </Col>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      mk: event.target.value
                    })
                  }}
                  control={{
                    id: "mk",
                    name: "mk",
                    caption: "M/K ratio",
                    placeholder: "M/K ratio",
                    value: lbsprParameterGroup.mk
                  }}
                />
              </Col>
            </Row>
          </Box>
        </div>
        <div className="mt-4">
          <Box title="Size Classes:">
            <Row>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      bin_width: event.target.value
                    })
                  }}
                  control={{
                    id: "binwidth",
                    name: "binwidth",
                    caption: "Bin width",
                    placeholder: "Bin width",
                    value: lbsprParameterGroup.bin_width
                  }}
                />
              </Col>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      bin_min: event.target.value
                    })
                  }}
                  control={{
                    id: "binmin",
                    name: "binmin",
                    caption: "Bin min",
                    placeholder: "Bin min",
                    value: lbsprParameterGroup.bin_min
                  }}
                />
              </Col>
              <Col md={3}>
                <InputText
                  type="text"
                  onChange={event => {
                    setLbsprParameterGroup({
                      ...lbsprParameterGroup,
                      bin_max: event.target.value
                    })
                  }}
                  control={{
                    id: "binmax",
                    name: "binmax",
                    caption: "Bin max",
                    placeholder: "Bin max",
                    value: lbsprParameterGroup.bin_max
                  }}
                />
              </Col>
            </Row>
          </Box>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="m-1 text-white"
            color="secondary"
            onClick={onCloseButtonPressed}
          >
            Close
          </Button>
          <Button
            size="sm"
            className="m-1"
            color="primary"
            onClick={onActionButtonPressed}
          >
            {toPascalCase(intention)}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default LbsprParameterGroupModal
