import Login from "../containers/Login"
import Home from "../containers/Home"

import Images from "../containers/Images"
import QualityControls from "../containers/QualityControls"
import ResetPassword from "../containers/ResetPassword"
import Users from "../containers/Users"
import LbsprParameterGroups from "../containers/LbsprParameterGroups"

interface ApplicationRoute {
  path: string
  component: any
  title: string
  exact: boolean
  isPrivate: boolean
}

export const routes: Array<ApplicationRoute> = [
  {
    path: "/login",
    component: Login,
    title: "Login",
    exact: true,
    isPrivate: false
  },
  {
    path: "/reset/:token",
    component: ResetPassword,
    title: "ResetPassword",
    exact: true,
    isPrivate: false
  },
  {
    path: "/*",
    exact: false,
    component: Home,
    title: "Home",
    isPrivate: true
  }
]

export const componentRoutes: Array<ApplicationRoute> = [
  {
    path: "",
    exact: true,
    component: Images,
    title: "Images",
    isPrivate: true
  },
  {
    path: "images",
    exact: true,
    component: Images,
    title: "Images",
    isPrivate: true
  },
  {
    path: "quality_controls",
    exact: true,
    component: QualityControls,
    title: "Quality Controls",
    isPrivate: true
  },
  {
    path: "lbspr_parameter_groups",
    exact: true,
    component: LbsprParameterGroups,
    title: "LBSPR Parameter groups",
    isPrivate: true
  },
  {
    path: "users",
    exact: true,
    component: Users,
    title: "Users",
    isPrivate: true
  }
]
