import React from "react"

interface Props {
  id?: string | undefined
  className?: string | undefined
  children?: any
}

const Badge: React.FC<Props> = props => {
  return (
    <React.Fragment>
      <span className={`badge ${props.className}`} id={props.id ?? ""}>
        {props.children}
      </span>
    </React.Fragment>
  )
}

export default Badge
