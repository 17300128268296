import React, { useEffect, useState } from "react"
import { Image } from "../../../interfaces"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import { StatusCode } from "../../../networking"
import { HalfCircleSpinner } from "react-epic-spinners"
import { downloadImageThumbnail } from "../../../networking/api"

interface Props {
  image: Image
}

const ThumbnailColumn: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [image, setImage] = useState<Image | undefined>(undefined)
  const [imageSource, setImageSource] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // on load
  useEffect(() => {
    setIsLoading(true)
    setImage(props.image)

    downloadImageThumbnail(sessionToken, props.image.id)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          const imgSrc = window.URL.createObjectURL(new Blob([response.data]))
          setImageSource(imgSrc)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  if (!image) return <div></div>

  return (
    <React.Fragment>
      <div style={{ height: "80px" }}>
        {isLoading || !image ? (
          <HalfCircleSpinner color="#575757" className={"mx-5"} />
        ) : (
          <img alt={`${image.native_key}.png`} src={imageSource} />
        )}
      </div>
    </React.Fragment>
  )
}

export default ThumbnailColumn
