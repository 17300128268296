import { nanoid } from "nanoid"
import { Col } from "reactstrap"

export enum TabButtonSize {
  LARGE,
  SMALL
}

interface Props {
  icon?: string
  caption: string
  onClick?: any
  isActive?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  size: TabButtonSize
}

const TabButton: React.FC<Props> = props => {
  let classes = `${props.isDisabled ? "lbspr-tab-disabled" : ""} p-1`

  // add size classes
  if (props.size === TabButtonSize.LARGE) {
    classes = `${classes} ${props.isActive ? "lbspr-tab-active" : "lbspr-tab"}`
  } else if (props.size === TabButtonSize.SMALL) {
    classes = `${classes} ${
      props.isActive ? "lbspr-tab-active--small" : "lbspr-tab--small"
    }`
  }

  let style = props.isDisabled
    ? { cursor: "not-allowed" }
    : { cursor: "pointer" }

  const onTabClicked = () => {
    if (!props.isDisabled) props.onClick()
  }

  return (
    
    <Col
      key={nanoid()}
      className={classes}
      onClick={onTabClicked}
      style={style}
    >
      {props.icon && (
        <div>
          <span className={`mx-1 icon ${props.icon}`} />
        </div>
      )}

      {props.caption}
    </Col>
  )
}

export default TabButton
