import { ImageFilter } from "../interfaces"

/**
 * The ImageFilterHelper provides utility methods to assist with
 * interacting with LBSPR filters.
 */
export class ImageFilterHelper {

  /**
   * Check if a date range is specified in the image filter.
   * 
   * @param imageFilter 
   * @returns if a date range is specified in the image filter
   */
  public static isDateRangeSpecified(imageFilter: ImageFilter): boolean {
    return imageFilter.from !== undefined && imageFilter.to !== undefined
  }

  /**
   * Check if the image filter is valid for LBSPR requests
   * 
   * @param imageFilter 
   * @returns if the image filter is valid for LBSPR requests
   */
  public static isValidForLbsprLengths(imageFilter: ImageFilter): boolean {
    return (
      imageFilter.species !== undefined && imageFilter.country !== undefined
    )
  }

  /**
   * Convert the image filter to an object that the LBSPR
   * rest api can process.
   * 
   * @param imageFilter 
   * @returns image filter as object processable by LBSPR rest api
   */
  public static convertToQueryParameters(imageFilter: ImageFilter) {
    let queryParameters = {}
    
    if (imageFilter.species) {
        queryParameters["filter_identified"] = true
    }

    if (imageFilter.species)
      queryParameters["filter_species"] = imageFilter.species
    if (imageFilter.country)
      queryParameters["filter_location"] = imageFilter.country
    if (imageFilter.user) queryParameters["filter_users"] = imageFilter.user
    if (imageFilter.from) queryParameters["filter_from"] = imageFilter.from
    if (imageFilter.to) queryParameters["filter_to"] = imageFilter.to

    return queryParameters
  }
}
