import React, { useEffect, useState } from "react"
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Col
} from "reactstrap"
import InputSelect from "./InputSelect"

interface Props {
  currentPage: number
  pageSize: number
  totalRecords: number
  handler: any
}

const Paginator: React.FC<Props> = props => {
  // local state
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(8)
  const [totalRecords, setTotalRecords] = useState<number>(0)

  // on props update
  useEffect(() => {
    setCurrentPage(props.currentPage)
    setPageSize(props.pageSize)
    setTotalRecords(props.totalRecords)
  }, [props])

  // the amount of pages to display before and after the current page
  const PAGE_BUFFER = 6

  // the total amount of pages available
  let totalPages = Math.floor(totalRecords / pageSize)

  let previousPageNumber = currentPage > 0 ? currentPage - 1 : 0
  let nextPageNumber = currentPage + 1

  // an array of pages to be displayed
  let pagesToDisplay: Array<number> = []

  pagesToDisplay.push(currentPage)

  // add previous pages to pagination
  for (let x = 1; x <= PAGE_BUFFER; x++) {
    let pageNumber = currentPage - x
    if (pageNumber < 0) break
    pagesToDisplay.push(pageNumber)
  }

  // add next pages to pagination
  for (let x = 1; x <= PAGE_BUFFER; x++) {
    let pageNumber = currentPage + x
    if (pageNumber > totalPages) break
    pagesToDisplay.push(pageNumber)
  }

  // sort pages in ascending order
  pagesToDisplay.sort((a, b) => a - b)

  const handleResultSizeChange = resultsPageSize => {
    props.handler(currentPage, resultsPageSize)
  }

  // results per page options
  let options = [
    {
      id: 2,
      name: "Display 2 results per page."
    },
    {
      id: 4,
      name: "Display 4 results per page."
    },
    {
      id: 8,
      name: "Display 8 results per page."
    },
    {
      id: 16,
      name: "Display 16 results per page."
    },
    {
      id: 32,
      name: "Display 32 results per page."
    }
  ]

  return (
    <React.Fragment>
      <FormGroup row className="pagination-top">
        <Col className="pagination-controls">
          <Pagination aria-label="Image result pagination">
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink
                href="#"
                onClick={() => {
                  props.handler(0, pageSize)
                }}
              >
                First
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                previous
                href="#"
                onClick={() => {
                  props.handler(previousPageNumber, pageSize)
                }}
              >
                Prev
              </PaginationLink>
            </PaginationItem>

            {pagesToDisplay.map((value, i) => (
              <PaginationItem active={value === currentPage} key={value}>
                <PaginationLink
                  href="#"
                  onClick={() => {
                    props.handler(value, pageSize)
                  }}
                >
                  {value + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink
                next
                href="#"
                onClick={() => {
                  props.handler(nextPageNumber, pageSize)
                }}
              >
                Next
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink
                href="#"
                onClick={() => {
                  props.handler(totalPages, pageSize)
                }}
              >
                Last
              </PaginationLink>
            </PaginationItem>
          </Pagination>
          <p>
            Displaying page {currentPage + 1} of {totalPages + 1}.
          </p>
        </Col>
        <Col className="pagination-controls">
          <InputSelect
            onChange={event => handleResultSizeChange(event.target.value)}
            control={{ value: pageSize, isDisabled: false, options: options }}
            isDisabled={false}
            className="mr-2"
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

export default Paginator
