import React, { useEffect, useState } from "react"
import moment from "moment"

import ListView from "../components/ListView"
import ImageModal from "./modals/ImageModal"
import MetaModal from "./modals/MetaModal"
import { Button, Col, Row } from "reactstrap"

import { toPascalCase } from "../../utility/helpers"
import Paginator from "../components/Paginator"
import ConfidenceBadge from "../components/ConfidenceBadge"
import LocationColumn from "../components/columns/LocationColumn"
import SpeciesColumn from "../components/columns/SpeciesColumn"
import { StatusCode } from "../../networking"
import { useGlobalState } from "../.."
import { GlobalStateKey } from "../../utility/globalStateKey"
import { Image, ImageFilter, ImagePagination } from "../../interfaces"
import CountryInputSelect from "../components/inputs/CountryInputSelect"
import SpeciesInputSelect from "../components/inputs/SpeciesInputSelect"
import UsersInputSelect from "../components/inputs/UsersInputSelect"
import ThumbnailColumn from "../components/columns/ThumbnailColumn"
import Avatar from "../components/Avatar"
import { Language } from "../../language"
import LbsprModal from "./modals/LbsprModal"
import { defaultImageFilter } from "../../defaults"
import { toast } from "react-hot-toast"
import { getImages } from "../../networking/api"
import InputText from "../components/InputText"
import { ImageFilterHelper } from "../../classes/ImageFilterHelper"

const Images: React.FC = () => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )
  const [isLoading, setIsLoading] = useGlobalState(GlobalStateKey.IS_LOADING)

  // local state
  const [imagePagination, setImagePagination] = useState<
    ImagePagination | undefined
  >(undefined)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(8)

  const [image, setImage] = useState<Image | undefined>(undefined)
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false)
  const [imageMetaData, setImageMetaData] = useState<any>(undefined)
  const [isMetaModalOpen, setIsMetaModalOpen] = useState<boolean>(false)
  const [isLbsprModalOpen, setIsLbsprModalOpen] = useState<boolean>(false)

  const [imageFilter, setImageFilter] =
    useState<ImageFilter>(defaultImageFilter)

  // on update
  useEffect(() => {
    // check requirements
    if (
      (imageFilter.from || imageFilter.to) &&
      (!imageFilter.from || !imageFilter.to)
    ) {
      toast.error(Language.ERR_LBSPR_BOTH_DATES_REQUIRED)
      return
    }

    setImagePagination(undefined)
    setIsLoading(true)

    getImages(sessionToken, currentPage, pageSize, imageFilter)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          setImagePagination(response.data.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [currentPage, pageSize, imageFilter])

  // table headers
  const headers = [
    {
      caption: "Image",
      field: "image_thumbnail",
      classes: "text-left text-click"
    },
    { caption: "Species", field: "species", classes: "align-middle" },
    { caption: "Confidence", field: "confidence", classes: "align-middle" },
    { caption: "Location", field: "location", classes: "align-middle" },
    { caption: "Meta", field: "meta", classes: "align-middle text-click" },
    {
      caption: "Uploaded By",
      field: "uploaded_by",
      classes: "text-center align-middle"
    },
    { caption: "Created", field: "create_time", classes: "align-middle" }
  ]

  const renderHeader = headers => {
    return headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ))
  }

  const onOpenImagePressed = (image: Image) => {
    setIsImageModalOpen(true)
    setImage(image)
  }

  const openMetaPressed = (imageMeta: any) => {
    setIsMetaModalOpen(true)
    setImageMetaData(imageMeta)
  }

  const openPageNumberPressed = (page: number, size: number) => {
    setCurrentPage(page)
    setPageSize(size)
  }

  const onImageModalClose = () => {
    setIsImageModalOpen(false)
    setImage(undefined)
  }

  const onMetaModalClose = () => {
    setIsMetaModalOpen(false)
  }

  const onLbsprModalClose = () => {
    setIsLbsprModalOpen(false)
  }

  const onLbsprPressed = () => {
    if (!ImageFilterHelper.isValidForLbsprLengths(imageFilter)) {
      toast.error(Language.ERR_LBSPR_FILTER_REQUIRED)
      return false
    }

    setIsLbsprModalOpen(true)
  }

  const renderFilter = () => {
    return (
      <div>
        <div className="filter mb-2">
          <h5 className="mb-0">Filter Results</h5>
          <p>
            To run the LBSPR anaylsis, you must apply the species and country
            filters. The user and date filters are optional.
          </p>
          <Row>
            <Col md={2}>
              <SpeciesInputSelect
                message={Language.FI_BY_SPECIES}
                onSelect={value => {
                  setImageFilter({ ...imageFilter, species: value })
                }}
                selectedSpecies={imageFilter.species}
                control={{ caption: "Species *" }}
              />
            </Col>
            <Col md={2}>
              <CountryInputSelect
                onSelect={value => {
                  setImageFilter({ ...imageFilter, country: value })
                }}
                control={{ value: imageFilter.country, caption: "Country *" }}
                selectMessage={Language.FI_BY_COUNTRY}
              />
            </Col>
            <Col md={2}>
              <UsersInputSelect
                onSelect={value => {
                  setImageFilter({ ...imageFilter, user: value })
                }}
                selectedUser={imageFilter.user}
                control={{ caption: "User" }}
              />
            </Col>
            <Col md={2}>
              <InputText
                type="date"
                control={{
                  id: "from",
                  name: "from",
                  caption: "Date from",
                  value: imageFilter.from
                }}
                onChange={event => {
                  setImageFilter({ ...imageFilter, from: event.target.value })
                }}
              />
            </Col>
            <Col md={2}>
              <InputText
                type="date"
                control={{
                  id: "to",
                  name: "to",
                  caption: "Date to",
                  value: imageFilter.to
                }}
                onChange={event => {
                  setImageFilter({ ...imageFilter, to: event.target.value })
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={2} className="d-flex flex-column">
              <Button
                size={"sm"}
                color={"danger"}
                onClick={() => setImageFilter(defaultImageFilter)}
                className={"text-white mt-auto"}
              >
                <span className={`icon icon-bin p-2`} />
                {"Clear filter"}
              </Button>
            </Col>
            <Col md={2} className="d-flex flex-column">
              <Button
                size={"sm"}
                color={"primary"}
                onClick={onLbsprPressed}
                className={"text-white"}
              >
                <span className={`icon icon-pie-chart p-1`} />
                {"LBSPR"}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderRow = (headers, image: Image) => {
    let confidence: number | undefined = undefined
    if (image.species.length > 0) {
      // check if the species has been corrected from quality control
      if (image.species[0].correct_species) {
        confidence = -1
      } else {
        confidence = Number((image.species[0].confidence * 100).toFixed(2))
      }
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case "image_thumbnail":
          return (
            <td
              key={index}
              className={header.classes}
              onClick={() => onOpenImagePressed(image)}
            >
              <ThumbnailColumn image={image} />
            </td>
          )
        case "species":
          return (
            <td key={index} className={header.classes}>
              <SpeciesColumn
                image={image}
                speciesConfidence={confidence}
                keepOriginalSpecies={false}
                isEditingEnabled={false}
                onEditClicked={undefined}
              />
            </td>
          )
        case "confidence":
          return (
            <td key={index} className={header.classes}>
              <ConfidenceBadge image={image} />
            </td>
          )
        case "location":
          return (
            <td key={index} className={header.classes}>
              <LocationColumn
                image={image}
                isEditingEnabled={false}
                onEditClicked={undefined}
              />
            </td>
          )
        case "meta":
          return (
            <td
              key={index}
              className={header.classes}
              onClick={() => openMetaPressed(image.image_metas)}
            >
              View
            </td>
          )
        case "uploaded_by":
          return (
            <td key={index} className={header.classes}>
              <Avatar user={image.create_user} />
            </td>
          )
        case "create_time":
          return (
            <td key={index} className={header.classes}>
              {moment
                .utc(image.create_time)
                .local()
                .format("YYYY-MMM-DD hh:mm A")}
            </td>
          )
        default:
          return <td key={index} className={header.classes}></td>
      }
    })
    return tableTd
  }

  let bodyRows

  if (imagePagination && imagePagination.rows) {
    bodyRows = imagePagination.rows.map((row, index) => {
      return <tr key={index}>{renderRow(headers, row)}</tr>
    })
  }

  const iconName = "table"

  return (
    <div className={"px-3 pt-3"}>
      <div className={"d-flex flex-row justify-content-between"}>
        <div className={"d-flex flex-row"}>
          <h3>Images</h3>
        </div>
      </div>

      {/* Modals */}
      <MetaModal
        isModalOpen={isMetaModalOpen}
        metaData={imageMetaData}
        onModalCloseHandler={onMetaModalClose}
      />
      <ImageModal
        image={image}
        isModalOpen={isImageModalOpen}
        onModalCloseHandler={onImageModalClose}
      />

      <LbsprModal
        imageFilter={imageFilter}
        isModalOpen={isLbsprModalOpen}
        onModalCloseHandler={onLbsprModalClose}
      />

      {/* Image Result Filtering */}
      {renderFilter()}

      {imagePagination && imagePagination.rows && (
        <div>
          <ListView
            header={renderHeader(headers)}
            bodyRows={bodyRows}
            rows={imagePagination.rows || []}
            rowsCount={imagePagination.pageTotal}
            iconName={iconName}
            emptyCaption={Language.I_NO_IMAGES_AVAILABLE}
          />

          <Paginator
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={imagePagination.total}
            handler={openPageNumberPressed}
          />
        </div>
      )}
    </div>
  )
}

export default Images
