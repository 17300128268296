import { LbsprLength, LbsprParameterGroup, LbsprYearBin } from "../interfaces"
import { Language } from "../language"
import { Config } from "./Config"

export enum LbsprYearBinLabel {
  INDEX, // label lbspr year bins by their index
  KEY // label lbspr year bins by a formatted date string
}

/**
 * The LbsprYearBinCollection class represents a collection of
 * LBSPR year bins. An LBSPR year bin contains a subset of length
 * data between a defined start and end date. A year apart in total.
 */
export class LbsprYearBinCollection {
  // raw lbspr lengths returned by the API
  private rawLbsprLengths: Array<LbsprLength>
  // year bins created by the class
  private lbsprYearBins: Array<LbsprYearBin>
  // the date of the first year bin
  private fromDate: Date | undefined
  // the date of the last year bin
  private toDate: Date | undefined
  // warnings for the LBSPR year bins
  private warnings: Array<string>
  // the LBSPR parameter group associated
  private lbsprParameterGroup: LbsprParameterGroup

  constructor(
    lbsprLengths: Array<LbsprLength>,
    fromDate: Date | undefined,
    toDate: Date | undefined,
    lbsprParameterGroup: LbsprParameterGroup
  ) {
    this.rawLbsprLengths = lbsprLengths
    this.fromDate = fromDate
    this.toDate = toDate
    this.lbsprYearBins = []
    this.warnings = []
    this.lbsprParameterGroup = lbsprParameterGroup

    // create lbspr year bins from lengths
    this.createLbsprYearBins()

    // create warnings
    this.createWarnings()
  }

  /**
   * Creates LBSPR year bins from the LBSPR lengths class property.
   */
  private createLbsprYearBins() {
    if (this.fromDate && this.toDate) {
      let binStartDate = new Date(this.fromDate)
      let binEndDate = new Date(this.fromDate)
      binEndDate.setFullYear(this.fromDate.getFullYear() + 1)

      while (true) {
        // create a new year bin
        this.lbsprYearBins.push({
          yearStartDate: new Date(binStartDate),
          yearEndDate: new Date(binEndDate),
          lengths: []
        })

        // increment bin dates
        binStartDate = new Date(binEndDate)
        binEndDate = new Date(binEndDate)
        binEndDate.setFullYear(binStartDate.getFullYear() + 1)

        // break the loop if bin start date exceeds date range
        if (binStartDate.getTime() > this.toDate.getTime()) break
      }

      // assign lengths to year bins
      this.rawLbsprLengths.forEach(length => {
        const date = new Date(length.create_time)

        this.lbsprYearBins.forEach(yearBin => {
          if (date > yearBin.yearStartDate && date < yearBin.yearEndDate) {
            if (this.doesLengthMeetBinSize(length.length)) yearBin.lengths.push(length.length)
          }
        })
      })
    } else {
      this.rawLbsprLengths.forEach(length => {
        const date = new Date(length.create_time)

        let wasYearBinFound = false

        this.lbsprYearBins.forEach(yearBin => {
          if (
            date.getTime() > yearBin.yearStartDate.getTime() &&
            date.getTime() < yearBin.yearEndDate.getTime()
          ) {
            wasYearBinFound = true
            if (this.doesLengthMeetBinSize(length.length)) yearBin.lengths.push(length.length)
          }
        })

        if (!wasYearBinFound) {
          const startDate = new Date(date.getFullYear(), 0, 1)
          const endDate = new Date(date.getFullYear(), 11, 31)
          const yearBin: LbsprYearBin = {
            yearStartDate: startDate,
            yearEndDate: endDate,
            lengths: []
          }

          if (this.doesLengthMeetBinSize(length.length)) yearBin.lengths.push(length.length)

          this.lbsprYearBins.push(yearBin)
        }
      })
    }
  }

  /**
   * Checks if the length is less than the maximum bin size
   * @param length 
   * @returns if the length is less than the maximum bin size
   */
  private doesLengthMeetBinSize(length: number) {
    if (!this.lbsprParameterGroup.bin_max) return true
    return length <= this.lbsprParameterGroup.bin_max
  }

  /**
   * Get the LBSPR length bins labelled by the labelling technique.
   *
   * @param labelTechnique
   * @param onlyIncludeValid if the values returned must meet the min image requirement
   * @returns LBSPR lengths labelled by the label technique.
   */
  public getLabelledBins(
    labelTechnique: LbsprYearBinLabel,
    onlyIncludeValid: boolean
  ) {
    let labelledBins = {}
    this.lbsprYearBins.forEach((yearBin, index) => {
      if (onlyIncludeValid && yearBin.lengths.length < Config.MIN_IMAGES_LBSPR)
        return

      let key: string = ""

      if (labelTechnique === LbsprYearBinLabel.INDEX) {
        key = String(Object.keys(labelledBins).length)
      } else if (labelTechnique === LbsprYearBinLabel.KEY) {
        key = LbsprYearBinCollection.makeDayMonthYearBinLabel(yearBin)
      }

      // assign lengths to key
      labelledBins[key] = yearBin.lengths
    })
    return labelledBins
  }

  /**
   * Get the LBSPR year bins.
   *
   * @param onlyIncludeValid only return valid LBSPR year bins
   * @returns
   */
  public getBins(onlyIncludeValid: boolean): Array<LbsprYearBin> {
    const bins: Array<LbsprYearBin> = []
    this.lbsprYearBins.forEach(lbsprYearBin => {
      if (
        onlyIncludeValid &&
        lbsprYearBin.lengths.length >= Config.MIN_IMAGES_LBSPR
      ) {
        bins.push(lbsprYearBin)
      }

      if (!onlyIncludeValid) bins.push(lbsprYearBin)
    })
    return bins
  }

  /**
   * Creates warnings for the LBSPR year bins.
   */
  private createWarnings() {
    this.warnings = []

    if (Config.IS_LBSPR_DATA_WARNING_ENABLED) {
      this.warnings.push(
        `The system is in test mode. The minimum image restriction will be ignored.`
      )
    }

    this.lbsprYearBins.forEach(lbsprYearBin => {

      if (lbsprYearBin.lengths.length < Config.MIN_IMAGES_LBSPR) {
        const label =
          LbsprYearBinCollection.makeDayMonthYearBinLabel(lbsprYearBin)
        this.warnings.push(
          `The bin labelled "${label}" does not meet the minimum image requirement of ${Config.MIN_IMAGES_LBSPR}.`
        )
      }
    })

    if (!this.canRunAnalysis()) {
      this.warnings.push(Language.ERR_LBSPR_NO_RESULTS)
    }
  }

  /**
   * Returns DD/MM/YYYY label for the LBSPR year bin.
   *
   * @param lbsprYearBin
   * @param separator the separator
   * @returns DD/MM/YYYY label for the LBSPR year bin.
   */
  public static makeDayMonthYearBinLabel(
    lbsprYearBin: LbsprYearBin,
    separator: string | undefined = undefined
  ): string {
    let key: string = ""
    const startKey = LbsprYearBinCollection.getDayMonthYear(
      lbsprYearBin.yearStartDate
    )
    const endKey = LbsprYearBinCollection.getDayMonthYear(
      lbsprYearBin.yearEndDate
    )
    key = `${startKey} - ${endKey}`

    if (separator) key = key.replaceAll("/", separator)

    return key
  }

  /**
   * Returns single LBSPR year bin as an array keyed by 0.
   *
   * @param lbsprYearBin
   * @returns Single LBSPR year bin as an array keyed by 0.
   */
  public static convertToSingleYearLengths(lbsprYearBin: LbsprYearBin) {
    let object = {}
    object[0] = lbsprYearBin.lengths
    return object
  }

  /**
   * Get warnings.
   *
   * @returns warnings
   */
  public getWarnings(): Array<string> {
    return this.warnings
  }

  /**
   * Check if an LBSPR analysis is possible with the existing LBSPR year bins.
   *
   * @returns if LBSPR analysis is possible
   */
  public canRunAnalysis(): boolean {
    let canRunAnalysis = false
    this.lbsprYearBins.forEach(lbsprYearBin => {
      if (lbsprYearBin.lengths.length > Config.MIN_IMAGES_LBSPR) {
        canRunAnalysis = true
        return
      }
    })

    return canRunAnalysis
  }

  /**
   * Returns the date in DD/MM/YYYY format.
   * @param date
   * @returns the date in DD/MM/YYYY format.
   */
  public static getDayMonthYear(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  /**
   * Create a legend for the LBSPR Year Bin Collection as a string
   * 
   * @param lbsprYearBinCollection 
   * @returns a string
   */
  public static createLegendAsString(
    lbsprYearBinCollection: LbsprYearBinCollection
  ): string {
    let legendContent = "Legend:\n"

    lbsprYearBinCollection.getBins(true).map((lbsprYearBin, index) => {
      legendContent += `\n[${index}] ${LbsprYearBinCollection.makeDayMonthYearBinLabel(
        lbsprYearBin
      )}`
    })

    return legendContent
  }

  /**
   * Create a legend for the LBSPR Year Bin Collection as an array
   * @param lbsprYearBinCollection 
   * @returns an array
   */
  public static createLegendAsArray(
    lbsprYearBinCollection: LbsprYearBinCollection
  ): Array<String> {
    let legendContent: Array<String> = []

    lbsprYearBinCollection.getBins(true).map((lbsprYearBin, index) => {
      legendContent.push(
        `\n[${index}] ${LbsprYearBinCollection.makeDayMonthYearBinLabel(
          lbsprYearBin
        )}`
      )
    })

    return legendContent
  }

  /**
   * Creates a mock LBSPR Year Bin collection that can be used
   * for testing.
   * @returns
   */
  public static createMockLbsprYearBinCollection(lbsprParameterGroup: LbsprParameterGroup) {
    // the years to include in the mock collection
    const years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023]

    // an array of LBSPR lengths
    const lbsprLengths: Array<LbsprLength> = []

    // LBSPR year bin collection start date
    const startDate = new Date("January 01, 2015 00:00:00")

    // LBSPR year bin collection end date
    const endDate = new Date("January 01, 2023 00:00:00")

    // min and max numbers to include in length data
    const minLength: number = 14
    const maxLength: number = 55

    years.forEach(year => {
      for (let i = 0; i < 100; i++) {
        const createTime = new Date(`January 01, ${year} 00:00:01`)
        lbsprLengths.push({
          create_time: createTime.toISOString(),
          length: Math.floor(
            Math.random() * (maxLength - minLength + 1) + minLength
          )
        })
      }
    })

    // return new collection
    return new LbsprYearBinCollection(lbsprLengths, startDate, endDate, lbsprParameterGroup)
  }
}
