import React, { useEffect, useState } from "react"
import { FormGroup, Col, Input, Label } from "reactstrap"
import { Control } from "../../interfaces"

interface Props {
  control: Control
  isDisabled: boolean
  className: string
  onChange: any
}

const InputSelect: React.FC<Props> = props => {
  const [value, setValue] = useState<any>(undefined)

  // on props update
  useEffect(() => {
    setValue(props.control.value)
  }, [props])

  const createOptions = options => {
    if (options) {
      return options.map((option, index) => (
        <option key={index} value={option.id}>
          {option.name}
        </option>
      ))
    } else {
      return null
    }
  }

  return (
    <React.Fragment>
      <FormGroup row className={`mt-0 mb-0 ${props.className}`}>
        {props.control.caption && (
          <Label for={props.control.name} sm={12} className="font-weight-bold">
            {props.control.caption}
          </Label>
        )}
        <Col sm={12}>
          <Input
            type="select"
            bsSize="sm"
            name={props.control.name}
            id={props.control.id ? props.control.id : props.control.name}
            value={value || ""}
            onChange={props.onChange}
            disabled={props.isDisabled || props.control.isDisabled}
          >
            {createOptions(props.control.options)}
          </Input>
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

export default InputSelect
