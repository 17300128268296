export enum Language {
  /*
   * SUCCESS LANGUAGE LINES
   * ---------------------------------------------------------------
   * These language lines are displayed when an action or state
   * is successful.
   *
   */

  SUCCESS_IMAGE_LOCATION_UPDATED = "Image location has been updated!",
  SUCCESS_IMAGE_SPECIES_UPDATED = "Image species has been updated",
  SUCCESS_IMAGE_DELETED = "Image has been deleted!",
  SUCCESS_PREPARING_DOWNLOAD = "Preparing download...",
  SUCCESS_LBSPR_ZIP_COMPILING = "Results zip file is compiling...",
  SUCCESS_LBSPR_RESULTS_DOWNLOADING = "Results are downloading...",
  SUCCESS_LBSPR_ZIP_DOWNLOADING = "Results zip file is downloading.",
  SUCCESS_LBSPR_PARAMETER_GROUP_DELETED = "LBSPR parameter group has been deleted!",
  SUCCESS_LBSPR_PARAMETER_GROUP_CREATED = "LBSPR parameter group has been created!",
  SUCCESS_LBSPR_PARAMETER_GROUP_UPDATED = "LBSPR parameter group has been updated!",
  SUCCESS_FILTER_APPLIED = "Filter has been applied!",
  SUCCESS_USER_CREATED = "User has been created!",

  /*
   * ERROR LANGUAGE LINES
   * ---------------------------------------------------------------
   * These language lines are displayed when an action or state
   * has failed.
   *
   */
  ERR_INVALID_EMAIL_OR_PASSWORD = "Invalid email address or password.",
  ERR_INVALID_PASSWORD_FORMAT = "Password must contain at least 8 characters, 1 letter, and 1 number!",
  ERR_INVALID_EMAIL_ADDRESS_FORMAT = "Email address must be valid!",
  ERR_INVALID_CONFIRM_PASSWORD = "The confirmation password must be the same as the password!",
  ERR_LBSPR_FILTER_REQUIRED = "You must apply the species and country filters before running the LBSPR analysis!",
  ERR_NO_LBSPR_PARAMETER_GROUP_FOUND = "There are no parameters available to run the LBSPR analysis.",
  ERR_NO_LBSPR_IMAGES = "There are not enough images available to run the LBSPR analysis.",
  ERR_LBSPR = "Something went wrong whilst fetching the LBSPR results.",
  ERR_LBSPR_BOTH_DATES_REQUIRED = "To apply date filtering, a start and end date must be specified.",
  ERR_LBSPR_NO_RESULTS = "There are no valid LBSPR length bins available to view results.",

  /*
   * INFORMATION LANGUAGE LINES
   * ---------------------------------------------------------------
   * These language lines are displayed when an action or state
   * requires further information.
   *
   */
  I_CONFIRM_IMAGE_DELETE = "WARNING: You are about to remove an image. This action CANNOT be undone. Continue?",
  I_NO_USERS_AVAILABLE = "There are no users available at this time.",
  I_NO_IMAGES_AVAILABLE = "There are no images available at this time.",
  I_NO_MODEL_ESTIMATES_AVAILABLE = "There are no model estimates available at this time.",
  I_NO_LBSPR_PARAMETER_GROUPS_AVAILABLE = "There are no LBSPR parameter groups available at this time.",

  /*
   * LBSPR OUTCOME LANGUAGE LINES
   * ---------------------------------------------------------------
   * These language lines describe the output of an LBSPR analysis.
   *
   */

  /*
   * OTHER LANGUAGE LINES
   * ---------------------------------------------------------------
   * These language lines that don't fit into other categories.
   *
   */
  LBSPR_OUTCOME_VERY_OVERFISHED = "This population appears to be overfished. Recruitment is likely to be impaired and there is an urgent risk of collapse.", // SPR 0-20
  LBSPR_OUTCOME_OVERFISHED = "This population appears to be overfished. Fish are being harvested faster than stocks can replenish.", // SPR 20-40
  LBSPR_OUTCOME_SUSTAINABLE = "This population appears to be harvested sustainably. ", // SPR 40-80
  LBSPR_OUTCOME_HEALTHY = "This population is healthy and is not being heavily exploited.", // SPR 80-100

  // modal title
  MT_LBSPR = "LBSPR Analysis",
  MT_VIEW_META = "View Meta",
  MT_VIEW_IMAGE = "View Image",
  MT_CREATE_USER = "Create User",
  MT_EDIT_LOCATION = "Edit Location",
  MT_EDIT_SPECIES = "Edit Species",
  MT_CREATE_LBSPR_PARAMETER_GROUP = "Create LBSPR Parameter Group",
  MT_EDIT_LBSPR_PARAMETER_GROUP = "Edit LBSPR Parameter Group",

  // modal subtitle
  MS_LBSPR = "Use the controls below to run an LBSPR analysis.",
  MS_CREATE_USER = "Use the controls below to create a new user.",
  MS_EDIT_LOCATION = "Use the controls below to adjust image location data.",
  MS_EDIT_SPECIES = "Use the controls below to adjust image species data.",
  MS_CREATE_LBSPR_PARAMETER_GROUP = "Use the controls below to create a new LBSPR parameter group.",
  MS_EDIT_LBSPR_PARAMETER_GROUP = "Use the controls below to edit the LBSPR parameter group.",

  // tooltip text
  TT_DELETE = "Click to delete!",
  TT_EDIT = "Click to edit!",
  TT_RESET_PASSWORD = "Click to reset password!",

  // filter input text
  FI_BY_USER = "Filter by user...",
  FI_BY_SPECIES = "Filter by species...",
  FI_BY_COUNTRY = "Filter by country..."
}
