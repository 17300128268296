import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Tooltip } from "reactstrap"
import { Image } from "../../interfaces"

enum ConfidenceLevel {
  MANUAL = "MANUAL",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  VERY_LOW = "VERY LOW"
}

enum ConfidenceColor {
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger"
}

interface Props {
  image: Image
  ignoreManualEntry?: boolean | undefined
}

const ConfidenceBadge: React.FC<Props> = props => {
  // local state
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [tooltipId, setTooltipId] = useState<string>(nanoid())
  const [image, setImage] = useState<Image>()
  const [confidence, setConfidence] = useState<number | undefined>(undefined)

  // on props update
  useEffect(() => {
    setImage(props.image)

    // does confidence exist?
    if (props.image.species[0]) {
      let confidence

      if (props.image.species[0].correct_species && !props.ignoreManualEntry) {
        confidence = -1
      } else {
        confidence = Number(
          (props.image.species[0].confidence * 100).toFixed(2)
        )
      }

      setConfidence(confidence)
    } else {
      setConfidence(undefined)
    }
  }, [props])

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  let confidenceLevel: ConfidenceLevel | undefined = undefined
  let confidenceColor: string | undefined = undefined

  // establish confidence level and associated color
  if (confidence) {
    if (confidence > 80) {
      confidenceLevel = ConfidenceLevel.HIGH
      confidenceColor = ConfidenceColor.SUCCESS
    } else if (confidence > 70) {
      confidenceLevel = ConfidenceLevel.MEDIUM
      confidenceColor = ConfidenceColor.WARNING
    } else if (confidence > 50) {
      confidenceLevel = ConfidenceLevel.LOW
      confidenceColor = ConfidenceColor.DANGER
    } else if (confidence > 0) {
      confidenceLevel = ConfidenceLevel.VERY_LOW
      confidenceColor = ConfidenceColor.DANGER
    } else {
      confidenceLevel = ConfidenceLevel.MANUAL
      confidenceColor = ConfidenceColor.SUCCESS
    }
  }

  const text =
    confidenceLevel === ConfidenceLevel.MANUAL
      ? "A user has manually set the species of this image."
      : confidence + "%"

  return (
    <React.Fragment>
      {confidence ? (
        <div>
          <span
            className={`badge bg-${confidenceColor}`}
            id={`badge-${tooltipId}`}
          >
            {confidenceLevel === ConfidenceLevel.MANUAL && (
              <span className={`icon icon-user mx-1`} />
            )}
            {confidenceLevel ?? ""}
          </span>
          <Tooltip
            placement="top"
            target={`badge-${tooltipId}`}
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
          >
            {text}
          </Tooltip>
        </div>
      ) : (
        "N/A"
      )}
    </React.Fragment>
  )
}

export default ConfidenceBadge
