import React, { useEffect, useState } from "react"
import moment from "moment"
import ListView from "../components/ListView"
import ImageModal from "./modals/ImageModal"
import EditSpeciesModal from "./modals/EditSpeciesModal"

import Paginator from "../components/Paginator"
import ConfidenceBadge from "../components/ConfidenceBadge"
import { toPascalCase } from "../../utility/helpers"
import StatusBadge from "../components/StatusBadge"
import LocationColumn from "../components/columns/LocationColumn"
import SpeciesColumn from "../components/columns/SpeciesColumn"
import EditLocationModal from "./modals/EditLocationModal"
import RowDeleteButton from "../components/buttons/RowDeleteButton"
import { GlobalStateKey } from "../../utility/globalStateKey"
import { useGlobalState } from "../.."
import { Image, ImageMetaSpecies, ImagePagination } from "../../interfaces"
import { StatusCode } from "../../networking"
import ThumbnailColumn from "../components/columns/ThumbnailColumn"
import Badge from "../components/Badge"
import Avatar from "../components/Avatar"
import { Language } from "../../language"
import { toast } from "react-hot-toast"
import { deleteImage, getImages } from "../../networking/api"

const QualityControls: React.FC = () => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )
  const [isLoading, setIsLoading] = useGlobalState(GlobalStateKey.IS_LOADING)

  // local state
  const [imagePagination, setImagePagination] = useState<
    ImagePagination | undefined
  >(undefined)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(8)
  const [modifiedAt, setModifiedAt] = useState<number | undefined>(undefined)

  const [image, setImage] = useState<Image | undefined>(undefined)

  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false)
  const [isEditSpeciesModalOpen, setIsEditSpeciesModalOpen] =
    useState<boolean>(false)
  const [isEditLocationModalOpen, setIsEditLocationModalOpen] =
    useState<boolean>(false)

  // on update
  useEffect(() => {
    setImagePagination(undefined)
    setIsLoading(true)

    getImages(sessionToken, currentPage, pageSize, undefined)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          setImagePagination(response.data.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [currentPage, pageSize, modifiedAt])

  const headers = [
    {
      caption: "Image",
      field: "image_thumbnail",
      classes: "text-left text-click"
    },
    { caption: "Warnings", field: "warnings", classes: "align-middle" },
    { caption: "ML Species", field: "species", classes: "align-middle" },
    { caption: "ML Confidence", field: "confidence", classes: "align-middle" },
    {
      caption: "Location",
      field: "location",
      classes: "align-middle text-left"
    },
    {
      caption: "Uploaded By",
      field: "uploaded_by",
      classes: "text-center align-middle"
    },
    {
      caption: "Confirmed Species",
      field: "correct_species",
      classes: "align-middle"
    },
    {
      caption: "Status",
      field: "is_correct",
      classes: "align-middle text-center"
    },
    {
      caption: "Actions",
      field: "actions",
      classes: "text-center align-middle"
    }
  ]

  const renderHeader = headers => {
    return headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ))
  }

  const renderRow = (headers, image: Image) => {
    // warnings for the image
    let warningMessages: Array<string> = []

    let imageMetaSpecies: ImageMetaSpecies | undefined
    let correctSpecies: string | undefined = undefined
    let reviewClasses: string = ""
    let approveMessage: string | undefined = undefined
    if (image.species.length > 0) {
      imageMetaSpecies = image.species[0]

      // check if the species has been corrected from quality control
      if (image.species[0].correct_species) {
        correctSpecies = toPascalCase(image.species[0].correct_species)
      }

      let isCorrect = false
      if (imageMetaSpecies.correct_species) {
        isCorrect =
          imageMetaSpecies.correct_species === imageMetaSpecies.species
        reviewClasses += isCorrect ? "bg-success" : "bg-danger"
        approveMessage = isCorrect ? "APPROVED" : "REJECTED"
      }
    }

    // check data integrity
    let isSpeciesConfirmed: boolean = correctSpecies !== undefined
    let areLatitudeAndLongitudeSet: boolean = false
    let isCountrySet: boolean = false

    if (image.locations.length > 0) {
      image.locations.forEach(location => {
        areLatitudeAndLongitudeSet =
          location.latitude !== null && location.longitude !== null
        isCountrySet = location.country !== null
      })
    }

    // add warning messages if required
    if (!isSpeciesConfirmed) warningMessages.push("Species not confirmed.")
    if (!areLatitudeAndLongitudeSet)
      warningMessages.push("Lat/lng data missing.")
    if (!isCountrySet) warningMessages.push("Country data missing.")

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case "image_thumbnail":
          return (
            <td
              key={index}
              className={header.classes}
              onClick={() => openImageModal(image)}
            >
              <ThumbnailColumn image={image} />
            </td>
          )
        case "warnings":
          return (
            <td key={index} className={header.classes}>
              {warningMessages.length > 0 && (
                <StatusBadge messages={warningMessages} />
              )}
            </td>
          )
        case "species":
          return (
            <td key={index} className={header.classes}>
              <SpeciesColumn
                image={image}
                keepOriginalSpecies={true}
                isEditingEnabled={true}
                onEditClicked={() => openEditSpeciesModal(image)}
              />
            </td>
          )
        case "confidence":
          return (
            <td key={index} className={header.classes}>
              <ConfidenceBadge image={image} ignoreManualEntry={true} />
            </td>
          )
        case "location":
          return (
            <td key={index} className={header.classes}>
              <LocationColumn
                image={image}
                isEditingEnabled={true}
                onEditClicked={() => openEditLocationModal(image)}
              />
            </td>
          )
        case "uploaded_by":
          return (
            <td key={index} className={header.classes}>
              <Avatar user={image.create_user} />
            </td>
          )
        case "create_time":
          return (
            <td key={index} className={header.classes}>
              {moment
                .utc(image.create_time)
                .local()
                .format("YYYY-MMM-DD hh:mm A")}
            </td>
          )
        case "correct_species":
          return (
            <td key={index} className={header.classes}>
              {imageMetaSpecies
                ? toPascalCase(imageMetaSpecies.correct_species)
                : "N/A"}
            </td>
          )
        case "is_correct":
          return (
            <td key={index} className={header.classes}>
              <Badge className={reviewClasses}>{approveMessage}</Badge>
            </td>
          )
        case "actions":
          return (
            <td key={index} className={header.classes}>
              <RowDeleteButton
                onClick={() => {
                  onDeleteButtonPressed(image.id)
                }}
              />
            </td>
          )
        default:
          return (
            <td key={index} className={header.classes}>
              {image[header.field]}
            </td>
          )
      }
    })
    return tableTd
  }

  const onQualityControlUpdate = () => {
    setIsImageModalOpen(false)
    setIsEditLocationModalOpen(false)
    setIsEditSpeciesModalOpen(false)
    setModifiedAt(moment().unix())
  }

  const onDeleteButtonPressed = imageId => {
    let confirm = window.confirm(Language.I_CONFIRM_IMAGE_DELETE)

    if (confirm) {
      deleteImage(sessionToken, imageId).then(response => {
        if (response && response.status === StatusCode.OK) {
          toast.success(Language.SUCCESS_IMAGE_DELETED)
          onQualityControlUpdate()
        }
      })
    }
  }

  const openImageModal = (image: Image) => {
    setImage(image)
    setIsImageModalOpen(true)
  }

  const onImageModalClose = () => {
    setImage(undefined)
    setIsImageModalOpen(false)
  }

  const openEditSpeciesModal = (image: Image) => {
    setImage(image)
    setIsEditSpeciesModalOpen(true)
  }

  const onEditSpeciesModalClosed = () => {
    setImage(undefined)
    setIsEditSpeciesModalOpen(false)
  }

  const openEditLocationModal = (image: Image) => {
    setImage(image)
    setIsEditLocationModalOpen(true)
  }

  const onEditLocationModalClosed = () => {
    setImage(undefined)
    setIsEditLocationModalOpen(false)
  }

  const openPageNumberPressed = (page: number, size: number) => {
    setCurrentPage(page)
    setPageSize(size)
  }

  let bodyRows

  if (imagePagination && imagePagination.rows) {
    bodyRows = imagePagination.rows.map((row, index) => (
      <tr key={index}>{renderRow(headers, row)}</tr>
    ))
  }

  const iconName = "eye"

  return (
    <div className="p-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row">
          <h3>Quality Control</h3>
        </div>
      </div>

      <ImageModal
        image={image}
        isModalOpen={isImageModalOpen}
        onModalCloseHandler={onImageModalClose}
      />
      <EditSpeciesModal
        image={image}
        isModalOpen={isEditSpeciesModalOpen}
        onModalClose={onEditSpeciesModalClosed}
        onUpdate={onQualityControlUpdate}
      />
      <EditLocationModal
        image={image}
        isModalOpen={isEditLocationModalOpen}
        onModalClose={onEditLocationModalClosed}
        onUpdate={onQualityControlUpdate}
      />

      {imagePagination && imagePagination.rows && (
        <div>
          <ListView
            header={renderHeader(headers)}
            bodyRows={bodyRows}
            rows={imagePagination.rows || []}
            rowsCount={imagePagination.pageTotal}
            iconName={iconName}
            emptyCaption={Language.I_NO_IMAGES_AVAILABLE}
          />

          <Paginator
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={imagePagination.total}
            handler={openPageNumberPressed}
          />
        </div>
      )}
    </div>
  )
}

export default QualityControls
