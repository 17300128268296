import { useEffect, useState } from "react"
import InputSelect from "../InputSelect"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import { StatusCode } from "../../../networking"
import { Language } from "../../../language"
import { getUsers } from "../../../networking/api"
import { Control } from "../../../interfaces"

interface Props {
  selectedUser: string | undefined
  onSelect: any
  control?: Control
}

const UsersInputSelect: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [availableUsers, setAvailableUsers] = useState<Array<any>>([])
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined
  )

  // on props update
  useEffect(() => {
    setSelectedUser(props.selectedUser)
  }, [props])

  // on load
  useEffect(() => {
    setIsLoading(true)

    getUsers(sessionToken)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          let data: Array<any> = []
          response.data.data.forEach(element => {
            data.push({ id: element.id, name: element.email })
          })

          data.unshift({ id: undefined, name: Language.FI_BY_USER })

          setAvailableUsers(data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const onSelect = (species: string) => {
    setSelectedUser(species)
    props.onSelect(species)
  }

  return (
    <InputSelect
      onChange={event => onSelect(event.target.value)}
      control={{
        ...props.control,
        value: selectedUser,
        options: availableUsers
      }}
      isDisabled={isLoading}
      className=""
    />
  )
}

export default UsersInputSelect
