import React from "react"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import { useGlobalState } from "../../.."
import { Config } from "../../../classes/Config"
import { ImageFilterHelper } from "../../../classes/ImageFilterHelper"
import { LbsprExporter } from "../../../classes/LbsprExporter"
import {
  LbsprYearBinCollection,
  LbsprYearBinLabel
} from "../../../classes/LbsprYearBinCollection"
import {
  ImageFilter,
  LbsprExportAction,
  LbsprParameterGroup,
  LbsprYearBin
} from "../../../interfaces"
import { Language } from "../../../language"
import { StatusCode } from "../../../networking"
import {
  findLbsprParameterGroup,
  getLbsprLengths
} from "../../../networking/api"
import {
  fitAndPlotHistogram,
  plotHistogram,
  plotSprCircle
} from "../../../networking/lbspr"
import { GlobalStateKey } from "../../../utility/globalStateKey"
import { toPascalCase } from "../../../utility/helpers"
import DataTree from "../../components/lbspr/DataTree"
import ResultTable from "../../components/lbspr/ResultTable"
import TabButton, { TabButtonSize } from "../../components/lbspr/TabButton"
import Spinner from "../../components/Spinner"
import Box from "../../components/Box"
import { nanoid } from "nanoid"

enum LbsprStage {
  "IMPORT" = "IMPORT",
  "VISUALISE" = "VISUALISE",
  "FIT" = "FIT",
  "RESULTS" = "RESULTS",
  "EXPORT" = "EXPORT"
}

interface Props {
  imageFilter: ImageFilter
  isModalOpen: boolean
  onModalCloseHandler: any
}

const LbsprModal: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [lbsprStage, setLbsprStage] = useState<LbsprStage>(LbsprStage.IMPORT)
  //const [imageFilter, setImageFilter] = useState<ImageFilter>()
  const [lbsprYearBinCollection, setLbsprYearBinCollection] =
    useState<LbsprYearBinCollection>()
  const [selectedLbsprYearBin, setSelectedLbsprYearBin] = useState<
    LbsprYearBin | undefined
  >(undefined)
  const [lbsprParameterGroup, setLbsprParameterGroup] = useState<
    LbsprParameterGroup | undefined
  >(undefined)
  const [histogramImageFile, setHistogramImageFile] = useState<
    string | undefined
  >(undefined)
  const [histogramFitImageFile, setHistogramFitImageFile] = useState<
    string | undefined
  >(undefined)
  const [sprCircleImageFile, setSprCircleImageFile] = useState<
    string | undefined
  >(undefined)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isExporting, setIsExporting] = useState<boolean>(false)

  // actions
  let lbsprExporter: LbsprExporter | undefined = undefined
  if (sessionToken && lbsprYearBinCollection && lbsprParameterGroup) {
    lbsprExporter = new LbsprExporter(
      sessionToken,
      lbsprYearBinCollection,
      lbsprParameterGroup
    )
  }

  const exportActions: Array<LbsprExportAction> = [
    {
      icon: "icon-file-excel",
      caption: "Download .CSV file with lengths by year.",
      handler: () => {
        if (lbsprExporter) {
          setIsExporting(true)
          toast.success(Language.SUCCESS_PREPARING_DOWNLOAD)
          lbsprExporter.downloadLengthsAsCsv()
          toast.success(Language.SUCCESS_LBSPR_RESULTS_DOWNLOADING)
          setIsExporting(false)
        }
      }
    },
    {
      icon: "icon-file-excel",
      caption: "Download .CSV file with LBSPR year outputs.",
      handler: () => {
        if (lbsprExporter) {
          setIsExporting(true)
          toast.success(Language.SUCCESS_PREPARING_DOWNLOAD)
          lbsprExporter.downloadModelEstimatesAsCsv().finally(() => {
            toast.success(Language.SUCCESS_LBSPR_RESULTS_DOWNLOADING)
            setIsExporting(false)
          })
        }
      }
    },
    {
      icon: "icon-file-zip",
      caption: "Download .ZIP file containing graphics.",
      handler: () => {
        setIsExporting(true)
        toast.success(Language.SUCCESS_PREPARING_DOWNLOAD)
        if (lbsprExporter) {
          lbsprExporter
            .downloadOutputsAsZip()
            .then(() => {
              toast.success(Language.SUCCESS_LBSPR_ZIP_DOWNLOADING)
            })
            .finally(() => {
              setIsExporting(false)
            })
        }
      }
    }
  ]

  // on props update
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)

    let parameterGroup: LbsprParameterGroup | undefined = undefined

    if (props.isModalOpen) {
      setIsLoading(true)
      // find lbspr parameter group
      findLbsprParameterGroup(sessionToken, {
        species: props.imageFilter.species,
        country: props.imageFilter.country
      })
        .then(response => {
          if (response && response.status === StatusCode.OK) {
            parameterGroup = response.data.data
          } else {
            toast.error(Language.ERR_NO_LBSPR_PARAMETER_GROUP_FOUND)
          }
        })
        .finally(() => {
          setIsLoading(false)
          if (parameterGroup) {
            setLbsprParameterGroup({ ...parameterGroup })
            // get length data
            setIsLoading(true)
            getLbsprLengths(sessionToken, props.imageFilter)
              .then(response => {
                if (response && response.status === StatusCode.OK) {
                  let fromDate: Date | undefined = undefined
                  let toDate: Date | undefined = undefined
                  if (
                    ImageFilterHelper.isDateRangeSpecified(props.imageFilter)
                  ) {
                    fromDate = new Date(String(props.imageFilter.from))
                    toDate = new Date(String(props.imageFilter.to))
                  }

                  let lbsprYearBinCollection:
                    | LbsprYearBinCollection
                    | undefined = undefined

                  if (Config.IS_MOCK_LBSPR_ENABLED) {
                    lbsprYearBinCollection =
                      LbsprYearBinCollection.createMockLbsprYearBinCollection(parameterGroup!)
                  } else {
                    lbsprYearBinCollection = new LbsprYearBinCollection(
                      response.data.data,
                      fromDate,
                      toDate,
                      parameterGroup!
                    )
                  }

                  setLbsprYearBinCollection(lbsprYearBinCollection)

                  // set selected year bin
                  const firstLbsprYearBin =
                    lbsprYearBinCollection.getBins(true)[0]
                  if (firstLbsprYearBin) {
                    setSelectedLbsprYearBin(firstLbsprYearBin)
                  } else {
                    toast.error(Language.ERR_LBSPR_NO_RESULTS)
                  }
                } else {
                  toast.error(Language.ERR_LBSPR)
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
          } else {
            onCloseModalPressed()
          }
        })
    }
  }, [props])

  useEffect(() => {
    if (lbsprParameterGroup && lbsprYearBinCollection) {
      const lbsprRequest = {
        lengthData: lbsprYearBinCollection.getLabelledBins(
          LbsprYearBinLabel.INDEX,
          true
        ),
        parameters: lbsprParameterGroup
      }

      switch (lbsprStage) {
        case LbsprStage.VISUALISE:
          setIsLoading(true)

          plotHistogram(sessionToken, lbsprRequest)
            .then(response => {
              if (response && response.status === StatusCode.OK) {
                setHistogramImageFile(URL.createObjectURL(response.data))
              } else {
                toast.error(Language.ERR_LBSPR)
              }
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case LbsprStage.FIT:
          setIsLoading(true)
          fitAndPlotHistogram(sessionToken, lbsprRequest)
            .then(response => {
              if (response && response.status === StatusCode.OK) {
                setHistogramFitImageFile(URL.createObjectURL(response.data))
              } else {
                toast.error(Language.ERR_LBSPR)
              }
            })
            .finally(() => {
              setIsLoading(false)
            })
          break
        case LbsprStage.RESULTS:
          if (selectedLbsprYearBin) {
            setIsLoading(true)

            plotSprCircle(sessionToken, {
              lengthData:
                LbsprYearBinCollection.convertToSingleYearLengths(
                  selectedLbsprYearBin
                ),
              parameters: lbsprParameterGroup
            })
              .then(response => {
                if (response && response.status === StatusCode.OK) {
                  setSprCircleImageFile(URL.createObjectURL(response.data))
                } else {
                  toast.error(Language.ERR_LBSPR)
                }
              })
              .finally(() => {
                setIsLoading(false)
              })
          }

          break
        case LbsprStage.EXPORT:
          break
        default:
          break
      }
    }

    setIsExporting(false)
    setHistogramImageFile(undefined)
    setHistogramFitImageFile(undefined)
    setSprCircleImageFile(undefined)
  }, [lbsprStage, selectedLbsprYearBin])

  if (!props.isModalOpen) {
    return <div></div>
  }

  const onCloseModalPressed = () => {
    setLbsprStage(LbsprStage.IMPORT)
    props.onModalCloseHandler()
  }

  const renderControls = () => {
    return (
      <Row style={{ cursor: "pointer" }} className="p-2">
        {Object.keys(LbsprStage).map(value => {
          const canRunAnalysis = lbsprYearBinCollection?.canRunAnalysis()
          let stage: LbsprStage
          let icon: string = ""
          let title: string = ""

          switch (value) {
            case LbsprStage.IMPORT:
              stage = LbsprStage.IMPORT
              icon = "icon-database"
              title = "1. Import Data"
              break
            case LbsprStage.VISUALISE:
              stage = LbsprStage.VISUALISE
              icon = "icon-stats-bars"
              title = "2. Visualise"
              break
            case LbsprStage.FIT:
              stage = LbsprStage.FIT
              icon = "icon-target"
              title = "3. Fit"
              break
            case LbsprStage.RESULTS:
              stage = LbsprStage.RESULTS
              icon = "icon-file-text"
              title = "4. Results"
              break
            case LbsprStage.EXPORT:
              stage = LbsprStage.EXPORT
              icon = "icon-download"
              title = "5. Export"
              break
          }

          return (
            <TabButton
              icon={icon}
              caption={title}
              isDisabled={!canRunAnalysis}
              isActive={value === lbsprStage}
              size={TabButtonSize.LARGE}
              onClick={() => {
                setLbsprStage(stage)
              }}
            />
          )
        })}
      </Row>
    )
  }

  const renderLegendBox = (lbsprYearBinCollection: LbsprYearBinCollection) => {
    return (
      <div className="mt-4">
        <Box title="Legend:">
          <ul className="mb-0">
            {LbsprYearBinCollection.createLegendAsArray(
              lbsprYearBinCollection
            ).map(line => {
              return <li>{line}</li>
            })}
          </ul>
        </Box>
      </div>
    )
  }

  const renderErrorBox = (errorMessages: Array<string>) => {
    return (
      <Box title="Errors:">
      <p className="mb-0">There are problems with the data:</p>
      <ul className="mb-0">
        {errorMessages.map(warning => {
          return <li key={nanoid()}>{warning}</li>
        })}
      </ul>
    </Box>
    )
  }

  const renderModalBody = () => {
    if (lbsprYearBinCollection) {
      switch (lbsprStage) {
        case LbsprStage.IMPORT:
          const dataTreeObject = lbsprYearBinCollection.getLabelledBins(
            LbsprYearBinLabel.KEY,
            false
          )

          return (
            <div key={lbsprStage}>
              <p>
                Length data has been retrieved. You can use the tree view below
                to inspect the data before proceeding to the next steps. Click
                on a year to expand the length values.
              </p>

              {lbsprYearBinCollection.getWarnings().length > 0 && (
                <div className="mb-4">
                  {renderErrorBox(lbsprYearBinCollection.getWarnings())}
                </div>
              )}

              <DataTree data={dataTreeObject} />
            </div>
          )
        case LbsprStage.VISUALISE:
          return (
            <div key={lbsprStage}>
              <div className="text-center">
                {histogramImageFile && (
                  <img alt="LBSPR Histogram" src={histogramImageFile} />
                )}
              </div>
              {renderLegendBox(lbsprYearBinCollection)}
            </div>
          )
        case LbsprStage.FIT:
          return (
            <div key={lbsprStage}>
              <div className="text-center">
                {histogramFitImageFile && (
                  <img alt="LBSPR Fit" src={histogramFitImageFile} />
                )}
              </div>
              {renderLegendBox(lbsprYearBinCollection)}
            </div>
          )
        case LbsprStage.RESULTS:
          return (
            <div key={lbsprStage}>
              {/* Only display year bin selection if there is more than one bin available */}
              {lbsprYearBinCollection.getBins(Config.HIDE_EMPTY_LBSPR_YEAR_BINS)
                .length > 1 && (
                <div>
                  <p>
                    There are multiple years of data. Select a year from the
                    list below to examine the estimated LBSPR results:
                  </p>
                  <div>
                    <Row className="m-0 p-0">
                      {lbsprYearBinCollection
                        .getBins(Config.HIDE_EMPTY_LBSPR_YEAR_BINS)
                        .map(lbsprYearBin => {
                          return (
                            <TabButton
                              icon={undefined}
                              caption={LbsprYearBinCollection.makeDayMonthYearBinLabel(
                                lbsprYearBin
                              )}
                              isDisabled={false}
                              isActive={selectedLbsprYearBin === lbsprYearBin}
                              size={TabButtonSize.SMALL}
                              onClick={() => {
                                setSelectedLbsprYearBin(lbsprYearBin)
                              }}
                            />
                          )
                        })}
                    </Row>
                  </div>
                </div>
              )}

              {selectedLbsprYearBin && lbsprParameterGroup && !isLoading && (
                <div className="my-3">
                  <ResultTable
                    lbsprYearBin={selectedLbsprYearBin}
                    lbsprParameterGroup={lbsprParameterGroup}
                  />
                </div>
              )}

              {sprCircleImageFile && (
                <Box title="SPR Circle:">
                  <img
                    alt="LBSPR SPR Circle"
                    src={sprCircleImageFile}
                    width="320px"
                  />
                </Box>
              )}
            </div>
          )
        case LbsprStage.EXPORT:
          return (
            <Spinner isLoading={isExporting}>
              <div key={lbsprStage}>
                <Box title="Export Options:">
                  <Row style={{ cursor: "pointer" }} className="p-3">
                    {exportActions &&
                      exportActions.map(exportAction => {
                        return (
                          <TabButton
                            caption={exportAction.caption}
                            size={TabButtonSize.LARGE}
                            icon={exportAction.icon}
                            onClick={exportAction.handler}
                            isDisabled={isExporting}
                          />
                        )
                      })}
                  </Row>
                </Box>
              </div>
            </Spinner>
          )
      }
    }
  }

  return (
    <Modal isOpen={isModalOpen} centered>
      <ModalHeader>
        <div className="mb-0">
          {Language.MT_LBSPR}
          {lbsprParameterGroup && lbsprParameterGroup.name && (
            <a> ({toPascalCase(lbsprParameterGroup.name)})</a>
          )}
        </div>
        <small>{Language.MS_LBSPR}</small>
      </ModalHeader>
      <ModalBody>
        <Spinner isLoading={isLoading}>
          {renderControls()}
          <div className="pt-3" hidden={isLoading}>
            {renderModalBody()}
          </div>
        </Spinner>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="m-1 text-white"
            color="secondary"
            onClick={onCloseModalPressed}
          >
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default LbsprModal
