/**
 * The CsvWriter class provides functionality to create and
 * download CSV files. The class hides underlying complexity.
 */
export class CsvWriter {
  // the title of the CSV file
  private title: string
  // the headers of the CSV file
  private headers: Array<string>
  // the rows of the CSV file
  private rows: Array<string>
  // the raw content of the CSV file
  private rawContent: string | undefined

  /**
   * Create an instance of CsvWriter.
   *
   * @param headers the header rows of the CSV file
   * @param rows the body rows of the CSV file
   * @returns CsvWriter class instance
   */
  constructor(title: string, headers: Array<string>, rows: Array<string>) {
    this.title = title
    this.headers = headers
    this.rows = rows

    this.createRawContent()

    return this
  }

  /**
   * Creates the raw content of the CSV file using the provided
   * headers and rows.
   */
  private createRawContent() {
    this.rawContent = ""
    this.rawContent += this.headers.toString() + "\n"
    this.rows.map(csvDataRow => {
      this.rawContent += csvDataRow.toString() + "\r\n"
    })
  }

  /**
   * Downloads the raw content as a CSV file to the browser.
   */
  public download() {
    if (this.rawContent) {
      const blob = new Blob([this.rawContent], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement("a")

      anchor.setAttribute("href", url)
      anchor.setAttribute("download", `${this.title}.csv`)
      anchor.click()
    }
  }
}
