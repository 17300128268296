import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Tooltip } from "reactstrap"

interface Props {
  messages: Array<string>
}

const StatusBadge: React.FC<Props> = props => {
  // local state
  const [message, setMessage] = useState<string>("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipId, setTooltipId] = useState(nanoid())

  // on props update
  useEffect(() => {
    let m: string = ""
    if (props.messages) {
      m = "This image has problems:"

      props.messages.forEach(message => {
        m += "\n ▸" + message
      })
    }

    setMessage(m)
  }, [props])

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  return (
    <React.Fragment>
      <span className={`status-badge bg-danger`} id={`badge-${tooltipId}`}>
        <span className={`icon icon-warning`} />
      </span>
      <Tooltip
        placement="top"
        target={`badge-${tooltipId}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
      >
        {message}
      </Tooltip>
    </React.Fragment>
  )
}

export default StatusBadge
