import React, { useState } from "react"
import { UserProfile } from "../../interfaces"
import { nanoid } from "nanoid"
import { Tooltip } from "reactstrap"

interface Props {
  user: UserProfile
}

const Avatar: React.FC<Props> = props => {
  // local state
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [tooltipId, setTooltipId] = useState<string>(nanoid())

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  if (!props.user || !props.user.email) return <div></div>

  const src = `https://api.dicebear.com/5.x/initials/svg?seed=${props.user.email}&backgroundType=gradientLinear&fontFamily=Tahoma`

  return (
    <React.Fragment>
      <img id={`image-${tooltipId}`} src={src} alt="Avatar" height={"33px"} />
      <Tooltip
        placement="top"
        target={`image-${tooltipId}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
      >
        {props.user.email}
      </Tooltip>
    </React.Fragment>
  )
}

export default Avatar
