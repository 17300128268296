import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Route, Routes, useLocation } from "react-router-dom"

import logo from "../../images/logo.png"
import { mainMenuItems } from "../constants/menus"
import { componentRoutes } from "../constants/routes"

import MenuItem from "../components/MenuItem"
import { useGlobalState } from "../.."
import { GlobalStateKey } from "../../utility/globalStateKey"
import { StatusCode } from "../../networking"
import { getCurrentUser } from "../../networking/api"

const Home: React.FC = () => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )
  const [userProfile, setUserProfile] = useGlobalState(
    GlobalStateKey.USER_PROFILE
  )

  // local state
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  const location = useLocation()

  // check authentication
  useEffect(() => {
    if (!sessionToken) {
      setSessionToken(undefined)
      window.location.replace("/login")
    }

    // check if session token is valid
    if (sessionToken) {
      // set the user profile
      getCurrentUser(sessionToken).then(response => {
        if (response && response.status === StatusCode.OK) {
          setUserProfile(response.data.data)
        } else {
          setSessionToken(undefined)
          window.location.replace("/login")
        }
      })
    }
  }, [location])

  let className = "listview"
  if (isCollapsed) className += " listview-narrow"

  let groups: Array<string> = []
  if (userProfile && userProfile.user_groups) {
    groups = userProfile.user_groups.map(user_group => user_group.group.tag)
  }

  const toggleSidebarCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  let menuItems = mainMenuItems
    .map((item, index) => {
      if (item.authTag && !groups.includes(item.authTag)) return false
      if (item.caption.toLowerCase() === "logout") {
        return (
          <MenuItem
            key={index}
            url={item.url}
            caption={item.caption}
            iconName={item.iconName}
            location={location}
            iconsOnly={isCollapsed}
            onClick={() => {
              window.location.replace("/login")
            }}
          />
        )
      } else {
        return (
          <MenuItem
            key={index}
            url={item.url}
            caption={item.caption}
            iconName={item.iconName}
            location={location}
            iconsOnly={isCollapsed}
          />
        )
      }
    })
    .filter(Boolean)

  return (
    <Row className="m-0 p-0 d-flex flex-row flex-grow-1">
      <Col className={className}>
        <div className="header text-center border-bottom bg-secondary">
          {!isCollapsed && (
            <div className="p-0 pt-2 pb-2 mb-2 bg-white d-flex justify-content-center align-items-center">
              <img style={{ maxHeight: 28 }} src={logo} alt="JCUFish" />
            </div>
          )}
          {isCollapsed && (
            <div className="p-0 pt-2 pb-2 mb-2 bg-white d-flex justify-content-center align-items-center">
              <img style={{ height: 32 }} src={logo} alt="JCUFish" />
            </div>
          )}
        </div>
        <ul className="list-group">
          {menuItems}
          <MenuItem
            key={-1}
            url="#"
            caption="Toggle Sidebar"
            iconName="arrow-left"
            location=""
            iconsOnly={isCollapsed}
            onClick={toggleSidebarCollapse}
          />
        </ul>
        {userProfile && !isCollapsed && (
          <div className="small text-center mt-3">
            Logged in as: <br />
            {userProfile.email}
          </div>
        )}
      </Col>
      <Col className="m-0 p-0">
        <Routes>
          {componentRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </Col>
    </Row>
  )
}

export default Home
