import React from "react"
import { Table } from "reactstrap"

interface Props {
  header: string
  bodyRows: any
  rows: any
  rowsCount?: number
  iconName: string
  emptyCaption: string
}

const ListView: React.FC<Props> = props => {
  return (
    <React.Fragment>
      {props.rows && props.rows.length === 0 && (
        <div className="p-5 text-center">
          <div>
            <span className={`text-primary h2 icon icon-${props.iconName}`} />
          </div>
          <div className="mt-3">{props.emptyCaption}</div>
        </div>
      )}

      {props.rows && props.rows.length > 0 && (
        <Table size="sm" striped>
          <thead>
            <tr>{props.header}</tr>
          </thead>
          <tbody>{props.bodyRows}</tbody>
        </Table>
      )}
    </React.Fragment>
  )
}

export default ListView
