import { saveAs } from "file-saver"
import JSZip from "jszip"
import { LbsprParameterGroup } from "../interfaces"
import { ModelEstimates } from "../interfaces/lbspr"
import { StatusCode } from "../networking"
import {
  fitAndPlotHistogram,
  LbsprRequest,
  modelEstimates,
  plotEstimates,
  plotHistogram,
  plotSprCircle
} from "../networking/lbspr"
import { getHumanDateTime } from "../utility/helpers"
import { Config } from "./Config"
import { CsvWriter } from "./CsvWriter"
import {
  LbsprYearBinCollection,
  LbsprYearBinLabel
} from "./LbsprYearBinCollection"

/**
 * The LbsprExporter class provides functionality to export the
 * LBSPR data in several ways and hides underlying complexity.
 */
export class LbsprExporter {
  private sessionToken: string
  private lbsprYearBinCollection: LbsprYearBinCollection
  private lbsprParameterGroup: LbsprParameterGroup

  constructor(
    sessionToken: string,
    lbsprYearBinCollection: LbsprYearBinCollection,
    lbsprParameterGroup: LbsprParameterGroup
  ) {
    this.sessionToken = sessionToken
    this.lbsprYearBinCollection = lbsprYearBinCollection
    this.lbsprParameterGroup = lbsprParameterGroup
  }

  /**
   * Downloads the LBSPR year bin collection as a CSV
   * file.
   */
  public downloadLengthsAsCsv(): any {
    // get the year bins
    const lbsprYearBins = this.lbsprYearBinCollection.getBins(
      Config.HIDE_EMPTY_LBSPR_YEAR_BINS
    )

    // count the year bins
    const lbsprYearBinCount = lbsprYearBins.length

    // the largest length column
    let largestLengthsColumn = -1

    // csv data
    const csvHeaderRow: Array<string> = []
    let csvDataRows: Array<any> = []

    // find the largest amount of lengths
    lbsprYearBins.forEach(lbsprYearBin => {
      if (lbsprYearBin.lengths.length > largestLengthsColumn) {
        largestLengthsColumn = lbsprYearBin.lengths.length
      }
    })

    // populate with empty data
    csvDataRows = Array.from(Array(largestLengthsColumn).keys())
    for (let rowIndex = 0; rowIndex < csvDataRows.length; rowIndex++) {
      csvDataRows[rowIndex] = Array.from(
        Array(lbsprYearBinCount).map(() => undefined)
      )
    }

    // populate the data
    for (let columnIndex = 0; columnIndex < lbsprYearBinCount; columnIndex++) {
      const lbsprYearBin = lbsprYearBins[columnIndex]

      csvHeaderRow.push(
        LbsprYearBinCollection.makeDayMonthYearBinLabel(lbsprYearBin)
      )

      for (
        let rowIndex = 0;
        rowIndex < lbsprYearBin.lengths.length;
        rowIndex++
      ) {
        csvDataRows[rowIndex][columnIndex] = lbsprYearBin.lengths[rowIndex]
      }
    }

    // create and download CSV file
    const csvTitle = `${getHumanDateTime()} Lengths Export`
    new CsvWriter(csvTitle, csvHeaderRow, csvDataRows).download()
  }

  /**
   * Downloads the LBSPR modelled estimates as a CSV
   * file.
   */
  public async downloadModelEstimatesAsCsv(): Promise<any> {
    const lbsprYearBins = this.lbsprYearBinCollection.getBins(true)
    const estimates: Array<{ label: string; data: ModelEstimates }> = []

    const promises: Array<Promise<any>> = []

    lbsprYearBins.forEach(lbsprYearBin => {
      const lbsprRequest: LbsprRequest = {
        parameters: this.lbsprParameterGroup,
        lengthData:
          LbsprYearBinCollection.convertToSingleYearLengths(lbsprYearBin)
      }

      promises.push(
        modelEstimates(this.sessionToken, lbsprRequest).then(response => {
          if (response && response.status === StatusCode.OK) {
            estimates.push({
              label:
                LbsprYearBinCollection.makeDayMonthYearBinLabel(lbsprYearBin),
              data: response.data.data
            })
          }
        })
      )
    })

    await Promise.all(promises)

    // create CSV file
    const csvHeaderRow: Array<string> = ["LABEL", "SPR", "SL50", "SL95", "F/M"]
    const csvDataRows: any = []

    estimates.forEach(estimate => {
      csvDataRows.push([
        estimate.label,
        String(estimate.data.SPR),
        String(estimate.data.SL50),
        String(estimate.data.SL95),
        String(estimate.data.FM)
      ])
    })

    // create and download CSV file
    const csvTitle = `${getHumanDateTime()} Model Estimates Export`
    new CsvWriter(csvTitle, csvHeaderRow, csvDataRows).download()
  }

  /**
   * Downloads all of the graphical outputs as a ZIP
   * file.
   */
  public async downloadOutputsAsZip(): Promise<any> {
    const lbsprYearBins = this.lbsprYearBinCollection.getLabelledBins(
      LbsprYearBinLabel.INDEX,
      true
    )

    const isMoreThanOneYearBin = Object.keys(lbsprYearBins).length > 1

    const promises: Array<Promise<any>> = []
    const zipPromises: Array<Promise<any>> = []

    let combinedHistogramBlob: Blob | undefined = undefined
    let individualHistogramBlobs: Array<{ label: string; blob: Blob }> = []
    let combinedHistogramFitBlob: Blob | undefined = undefined
    let individualHistogramFitBlobs: Array<{ label: string; blob: Blob }> = []
    let estimatesPlotBlob: Blob | undefined = undefined
    let sprCirclePlotBlobs: Array<{ label: string; blob: Blob }> = []

    // add promise to fetch combined histogram
    promises.push(
      plotHistogram(this.sessionToken, {
        parameters: this.lbsprParameterGroup,
        lengthData: lbsprYearBins
      }).then(response => {
        if (response && response.status === StatusCode.OK) {
          combinedHistogramBlob = response.data
        }
      })
    )

    // add promise to fetch combined histogram with fit
    promises.push(
      fitAndPlotHistogram(this.sessionToken, {
        parameters: this.lbsprParameterGroup,
        lengthData: lbsprYearBins
      }).then(response => {
        if (response && response.status === StatusCode.OK) {
          combinedHistogramFitBlob = response.data
        }
      })
    )

    // add promise to fetch estimates plot for all years
    promises.push(
      plotEstimates(this.sessionToken, {
        parameters: this.lbsprParameterGroup,
        lengthData: lbsprYearBins
      }).then(response => {
        if (response && response.status === StatusCode.OK) {
          estimatesPlotBlob = response.data
        }
      })
    )

    // add promise to fetch spr circle plot for each year
    this.lbsprYearBinCollection.getBins(true).forEach(lbsprYearBin => {
      promises.push(
        plotSprCircle(this.sessionToken, {
          parameters: this.lbsprParameterGroup,
          lengthData:
            LbsprYearBinCollection.convertToSingleYearLengths(lbsprYearBin)
        }).then(response => {
          if (response && response.status === StatusCode.OK) {
            sprCirclePlotBlobs.push({
              label: LbsprYearBinCollection.makeDayMonthYearBinLabel(
                lbsprYearBin,
                "."
              ),
              blob: response.data
            })
          }
        })
      )

      promises.push(
        plotHistogram(this.sessionToken, {
          parameters: this.lbsprParameterGroup,
          lengthData:
            LbsprYearBinCollection.convertToSingleYearLengths(lbsprYearBin)
        }).then(response => {
          if (response && response.status == StatusCode.OK) {
            individualHistogramBlobs.push({
              label: LbsprYearBinCollection.makeDayMonthYearBinLabel(
                lbsprYearBin,
                "."
              ),
              blob: response.data
            })
          }
        })
      )

      promises.push(
        fitAndPlotHistogram(this.sessionToken, {
          parameters: this.lbsprParameterGroup,
          lengthData:
            LbsprYearBinCollection.convertToSingleYearLengths(lbsprYearBin)
        }).then(response => {
          if (response && response.status === StatusCode.OK) {
            individualHistogramFitBlobs.push({
              label: LbsprYearBinCollection.makeDayMonthYearBinLabel(
                lbsprYearBin,
                "."
              ),
              blob: response.data
            })
          }
        })
      )
    })

    // wait for all promises to resolve
    await Promise.all(promises)

    // zip file creation
    // https://github.com/eligrey/FileSaver.js
    // https://www.npmjs.com/package/jszip
    const zip = new JSZip()

    // add meta description file
    zip.file(
      "export meta.txt",
      LbsprExporter.createParameterGroupDescriptionString(
        this.lbsprParameterGroup
      )
    )

    // add combined histogram plot to zip
    if (combinedHistogramBlob) {
      zipPromises.push(
        this.convertBlobToBase64(combinedHistogramBlob).then(base64 => {
          zip.file("histogram all years/HISTOGRAM ALL YEARS.png", base64, {
            base64: true
          })
          zip.file(
            "histogram all years/legend.txt",
            LbsprYearBinCollection.createLegendAsString(
              this.lbsprYearBinCollection
            )
          )
        })
      )
    }

    // add combined histogram fit to zip
    if (combinedHistogramFitBlob) {
      zipPromises.push(
        this.convertBlobToBase64(combinedHistogramFitBlob).then(base64 => {
          zip.file(
            "histogram fit all years/HISTOGRAM FIT ALL YEARS.png",
            base64,
            { base64: true }
          )
          zip.file(
            "histogram fit all years/legend.txt",
            LbsprYearBinCollection.createLegendAsString(
              this.lbsprYearBinCollection
            )
          )
        })
      )
    }

    // add estimates plot to zip
    if (estimatesPlotBlob && isMoreThanOneYearBin) {
      zipPromises.push(
        this.convertBlobToBase64(estimatesPlotBlob).then(base64 => {
          zip.file("ESTIMATES PLOT ALL YEARS.png", base64, { base64: true })
        })
      )
    }

    // add individual spr circle plots to zip
    sprCirclePlotBlobs.forEach(sprCirclePlotBlob => {
      zipPromises.push(
        this.convertBlobToBase64(sprCirclePlotBlob.blob).then(base64 => {
          zip.file(
            `individual spr circle plots/SPR CIRCLE ${sprCirclePlotBlob.label}.png`,
            base64,
            {
              base64: true
            }
          )
        })
      )
    })

    // add individual histograms to zip
    individualHistogramBlobs.forEach(histogramBlob => {
      zipPromises.push(
        this.convertBlobToBase64(histogramBlob.blob).then(base64 => {
          zip.file(
            `individual histograms/HISTOGRAM ${histogramBlob.label}.png`,
            base64,
            {
              base64: true
            }
          )
        })
      )
    })

    // add individual fit histograms to zip
    individualHistogramFitBlobs.forEach(fitHistogramBlob => {
      zipPromises.push(
        this.convertBlobToBase64(fitHistogramBlob.blob).then(base64 => {
          zip.file(
            `individual histograms fitted/HISTOGRAM FITTED ${fitHistogramBlob.label}.png`,
            base64,
            { base64: true }
          )
        })
      )
    })

    // execute promises
    this.lbsprParameterGroup
    Promise.all(zipPromises).finally(() => {
      const lbsprParameterGroup = this.lbsprParameterGroup
      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, `${lbsprParameterGroup.species} LBSPR export.zip`)
      })
    })
  }

  /**
   * Converts a PNG blob object to a base64 string.
   * @param blob the blob to be converted
   * @returns base64 representation promise
   */
  private convertBlobToBase64(blob: Blob): Promise<any> {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise(resolve => {
      reader.onloadend = () => {
        let result = String(reader.result)
        result = result.replace("data:image/png;base64,", "")

        resolve(result)
      }
    })
  }

  /**
   * Creates a string containing parameter group
   * meta description.
   */
  private static createParameterGroupDescriptionString(
    lbsprParameterGroup: LbsprParameterGroup
  ) {
    let string = ""

    // title section
    string += `EXPORT META FILE (${getHumanDateTime()})`
    string += `\nThis document contains information about the parameters that were used to generate the artifacts in this folder.`

    // details
    string += `\n\nDetails:`
    string += `\n Species: ${lbsprParameterGroup.species ?? "not specified"}`
    string += `\n Country: ${lbsprParameterGroup.country ?? "not specified"}`

    // biology parameters
    string += `\n\nBiology parameters:`
    string += `\n L inf: ${lbsprParameterGroup.l_inf ?? "not specified"}`
    string += `\n L 50: ${lbsprParameterGroup.l_50 ?? "not specified"}`
    string += `\n L 95: ${lbsprParameterGroup.l_95 ?? "not specified"}`

    // size classes
    string += `\n\nSize classes:`
    string += `\n Bin width: ${
      lbsprParameterGroup.bin_width ?? "not specified"
    }`
    string += `\n Bin min: ${lbsprParameterGroup.bin_min ?? "not specified"}`
    string += `\n Bin max: ${lbsprParameterGroup.bin_max ?? "not specified"}`

    return string
  }
}
