import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import {
  Card,
  FormGroup,
  Button,
  Input,
  Label,
  CardHeader,
  CardBody,
  CardFooter
} from "reactstrap"

import logo from "../../images/logo.png"
import ocean from "../../images/ocean.jpg"

import { StatusCode } from "../../networking"
import { useGlobalState } from "../.."
import { GlobalStateKey } from "../../utility/globalStateKey"
import AlertMessage from "../components/AlertMessage"
import CurrentTime from "../components/CurrentTime"
import { Language } from "../../language"
import { toast } from "react-hot-toast"
import { login } from "../../networking/api"

const Login: React.FC = () => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [areInputsDisabled, setAreInputsDisabled] = useState<boolean>(false)

  const isAbleToProceed = username !== "" && password !== ""

  const sectionStyle = {
    backgroundImage: `url(${ocean})`,
    backgroundSize: "cover"
  }

  const onLoginPressed = () => {
    setAreInputsDisabled(true)

    login(username, password)
      .then(response => {
        // perform login if successful
        if (response && response.status === StatusCode.OK) {
          // set the session token
          setSessionToken(response.data.token)
        } else {
          toast.error(Language.ERR_INVALID_EMAIL_OR_PASSWORD)
        }
      })
      .finally(() => {
        setAreInputsDisabled(false)
      })
  }

  // redirect if the user is authenticated
  if (sessionToken) {
    return <Navigate to={"/"} />
  }

  return (
    <div
      className="container-fluid p-0 h-100 bg-primary d-flex flex-column align-items-center"
      style={sectionStyle}
    >
      <div className="d-flex h-100 align-items-center">
        <Card className="login p-4">
          <CardHeader className="text-center">
            <img src={logo} alt="JCUFish" />
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="user" className="mb-0">
                Email address
              </Label>
              <Input
                type="email"
                id="user"
                name="user"
                value={username}
                placeholder="Email Address"
                disabled={areInputsDisabled}
                onChange={event => {
                  setUsername(event.target.value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pwd" className="mb-0">
                Password
              </Label>
              <Input
                type="password"
                id="pwd"
                name="pwd"
                value={password}
                placeholder="Password"
                disabled={areInputsDisabled}
                onChange={event => {
                  setPassword(event.target.value)
                }}
              />
            </FormGroup>
            <Button
              className="bg-secondary text-white mt-3 w-100"
              onClick={onLoginPressed}
              disabled={!isAbleToProceed}
            >
              Login
            </Button>
          </CardBody>
          <CardFooter>
            <CurrentTime />
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

export default Login
