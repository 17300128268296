import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "../../styles/App.css"

import Spinner from "../components/Spinner"

import { routes } from "../constants/routes"
import { Toaster } from "react-hot-toast"

const App: React.FC = () => {

  return (
    <BrowserRouter>
      <div className="container-fluid p-0 d-flex flex-column h-100">
        <Spinner>
          <Toaster
            position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 10000
            }}
          />

          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Spinner>
      </div>
    </BrowserRouter>
  )
}

export default App
