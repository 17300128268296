import axios, { AxiosError, AxiosResponse } from "axios"
import toast from "react-hot-toast"

export enum StatusCode {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORISED = 401,
  INTERNAL_SERVER_ERROR = 500
}

export enum RequestMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete"
}

export enum RequestResponseType {
  BLOB = "blob",
  NORMAL = "normal"
}

export enum Api {
  LBSPR = "LBSPR",
  JCU_FISH = "JCU_FISH"
}

export interface RequestOptions {
  url: string
  sessionToken?: string | undefined
  method: RequestMethod
  queryParameters?: Object | undefined
  responseType: RequestResponseType
  body?: Object | undefined
  api?: Api
}

export async function makeRequest(
  requestOptions: RequestOptions
): Promise<AxiosResponse<any> | undefined> {
  // create request instance

  let options = {}

  if (requestOptions.sessionToken) {
    if (requestOptions.api && requestOptions.api === Api.LBSPR) {
      options = {
        headers: {
          "x-session-token": requestOptions.sessionToken
        }
      }
    } else {
      options = {
        headers: {
          "session-token": requestOptions.sessionToken
        }
      }
    }
  }

  // add request response type
  if (requestOptions.responseType !== RequestResponseType.NORMAL) {
    options["responseType"] = requestOptions.responseType
  }

  const requestInstance = axios.create(options)
  const data = requestOptions.body

  try {
    // make request and return response
    switch (requestOptions.method) {
      case RequestMethod.GET:
        return await requestInstance.get(requestOptions.url, {
          params: requestOptions.queryParameters
        })
      case RequestMethod.POST:
        return await requestInstance.post(requestOptions.url, data, {
          params: requestOptions.queryParameters
        })
      case RequestMethod.PUT:
        return await requestInstance.put(requestOptions.url, data, {
          params: requestOptions.queryParameters
        })
      case RequestMethod.PATCH:
        return await requestInstance.patch(requestOptions.url, data, {
          params: requestOptions.queryParameters
        })
      case RequestMethod.DELETE:
        return await requestInstance.delete(requestOptions.url, {
          params: requestOptions.queryParameters
        })
      default:
        return undefined
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      if (error && error.response) {
        const status = error.response.status

        // handle unauthorised request
        if (status === StatusCode.UNAUTHORISED) {
          window.location.replace("/login")
          return undefined
        } else if (status === StatusCode.INTERNAL_SERVER_ERROR) {
          if (requestOptions.api == Api.LBSPR) {

            // blob requests require special handling
            const requestBody = JSON.parse(await error.response.data.text())
            toast.error(`LBSPR Error: ${requestBody.data.error}`)
          }
        }
      }
    }
  }
}
