import React, { useEffect, useState } from "react"
import RowEditButton from "../buttons/RowEditButton"
import { Image } from "../../../interfaces"
import { Countries } from "../../../classes/Countries"

interface Props {
  image: Image
  isEditingEnabled: boolean
  onEditClicked: any
}

const LocationColumn: React.FC<Props> = props => {
  // local state
  const [isEditingEnabled, setIsEditingEnabled] = useState<boolean>(false)

  // on props update
  useEffect(() => {
    setIsEditingEnabled(props.isEditingEnabled)
  }, [props])

  // the image country
  let country: string | undefined = "N/A"

  // the country flag url
  let countryFlagUrl: string | undefined = ""

  // get country from image
  if (props.image.locations.length > 0) {
    const coords = props.image.locations[0]

    let locationCountry = coords.country
    
    if (locationCountry) {
      country = locationCountry
      const code = Countries.getCodeFromName(locationCountry)
      countryFlagUrl = `https://flagsapi.com/${code}/shiny/32.png`
    }
  }

  // if there is no image meta location, then editing should be disabled
  if (isEditingEnabled && !props.image.locations.length) {
    setIsEditingEnabled(false)
  }

  return (
    <React.Fragment>
      {country && (
        <div className="d-inline">
          <img
            alt={countryFlagUrl}
            src={countryFlagUrl}
            style={{ maxWidth: "50px" }}
          />
          <p className="d-inline m-2">{country}</p>
        </div>
      )}
      {isEditingEnabled && (
        <div className="float-end d-inline">
          <RowEditButton onClick={props.onEditClicked} />
        </div>
      )}
    </React.Fragment>
  )
}

export default LocationColumn
