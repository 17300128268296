interface Props {
    title: string
    children?: any
}

const Box: React.FC<Props> = (props) => {
    return (
        <div>
            <div className="box-header">
                {props.title}
            </div>
            <div className="box-body">
                {props.children}
            </div>
        </div>
    )
}

export default Box