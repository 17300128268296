import React, { useEffect, useState } from "react"
import { FormGroup, Col, Input, Label } from "reactstrap"
import { Control } from "../../interfaces"
import { InputType } from "reactstrap/types/lib/Input"
import InputHelpText, { InputHelp } from "./InputHelp"

interface Props {
  type: InputType
  onChange: any
  control: Control
  isDisabled?: boolean
  className?: string
  inputHelp?: InputHelp
}

const InputText: React.FC<Props> = props => {
  const [value, setValue] = useState<string>("")

  // on props update
  useEffect(() => {
    if (props.control.value === 0) {
      setValue("0")
    } else {
      setValue(props.control.value)
    }
  }, [props])

  return (
    <React.Fragment>
      <FormGroup row className={`mt-0 mb-0 ${props.className}`}>
        {props.control.caption && (
          <Label for={props.control.name} sm={12}>
            {props.control.caption}
            {props.inputHelp && (
              <div className={`d-inline text-end`}>
                <InputHelpText
                  message={props.inputHelp.message}
                  title={props.inputHelp.title}
                />
              </div>
            )}
          </Label>
        )}
        <Col sm={12}>
          <Input
            type={props.type || "text"}
            bsSize="sm"
            name={props.control.name}
            id={props.control.id ? props.control.id : props.control.name}
            value={value || ""}
            onChange={props.onChange}
            disabled={props.isDisabled || props.control.isDisabled}
            placeholder={props.control.placeholder || ""}
          ></Input>
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

export default InputText
