import { useEffect, useState } from "react"
import { getHumanDateTime } from "../../utility/helpers"
import React from "react"

const CurrentTime: React.FC = () => {
  // local state
  const [text, setText] = useState<string>(getHumanDateTime())

  // interval time update
  useEffect(() => {
    const interval = setInterval(() => {
      setText(getHumanDateTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return <React.Fragment>{text}</React.Fragment>
}

export default CurrentTime
