import { useEffect, useState } from "react"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import InputText from "../../components/InputText"
import { StatusCode } from "../../../networking"
import {
  isEmailAddressValid,
  isPasswordValid
} from "../../../utility/validator"
import { Language } from "../../../language"
import { toast } from "react-hot-toast"
import { addUser } from "../../../networking/api"

interface Props {
  isModalOpen: boolean
  onModalClose: any
  onUpdate: any
}

const CreateUserModal: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(
    undefined
  )

  // on props update
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)
  }, [props])

  const onCreateButtonPressed = () => {
    if (email && password) {
      addUser(sessionToken, email, password).then(response => {
        if (response && response.status === StatusCode.OK) {
          toast.success(Language.SUCCESS_USER_CREATED)
          props.onUpdate()
          onCloseButtonPressed()
        }
      })
    }
  }

  const onCloseButtonPressed = () => {
    setEmail(undefined)
    setPassword(undefined)
    setConfirmPassword(undefined)
    props.onModalClose()
  }

  const isPwdValid = password && isPasswordValid(password)
  const isEmailValid = email && isEmailAddressValid(email)
  const doPasswordsMatch = password === confirmPassword

  const isCreateButtonDisabled =
    !isEmailValid || !isPwdValid || !doPasswordsMatch

  return (
    <Modal isOpen={isModalOpen} contentClassName={""} centered>
      <ModalHeader>
        <div className="mb-0">{Language.MT_CREATE_USER}</div>
        <small>{Language.MS_CREATE_USER}</small>
      </ModalHeader>
      <ModalBody>
        <Row>
          {email && !isEmailValid && (
            <p className={"text-danger mb-0"}>
              {Language.ERR_INVALID_EMAIL_ADDRESS_FORMAT}
            </p>
          )}
          {password && !isPwdValid && (
            <p className={"text-danger mb-0"}>
              {Language.ERR_INVALID_PASSWORD_FORMAT}
            </p>
          )}
          {password && !doPasswordsMatch && (
            <p className={"text-danger mb-0"}>
              {Language.ERR_INVALID_CONFIRM_PASSWORD}
            </p>
          )}
          <Col md={12}>
            <InputText
              type="text"
              onChange={event => {
                setEmail(event.target.value)
              }}
              control={{
                id: "email",
                name: "email",
                caption: "Email address",
                placeholder: "Email address",
                value: email
              }}
            />
          </Col>
          <Col md={6}>
            <InputText
              type="password"
              onChange={event => {
                setPassword(event.target.value)
              }}
              control={{
                id: "password",
                name: "password",
                caption: "Password",
                placeholder: "Password",
                value: password
              }}
            />
          </Col>
          <Col md={6}>
            <InputText
              type="password"
              onChange={event => {
                setConfirmPassword(event.target.value)
              }}
              control={{
                id: "confirm",
                name: "confirm",
                caption: "Confirm password",
                placeholder: "Confirm password",
                value: confirmPassword
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="m-1 text-white"
            color="secondary"
            onClick={onCloseButtonPressed}
          >
            Close
          </Button>
          <Button
            size="sm"
            className="m-1"
            color="primary"
            onClick={onCreateButtonPressed}
            disabled={isCreateButtonDisabled}
          >
            Create
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CreateUserModal
