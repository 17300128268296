import React from "react"
import ClassNames from "classnames"
import { HalfCircleSpinner } from "react-epic-spinners"
import "../../styles/Spinner.scss"
import { useGlobalState } from "../.."
import { GlobalStateKey } from "../../utility/globalStateKey"

interface Props {
  isLoading?: boolean
  children: any
}

const Spinner: React.FC<Props> = props => {
  const [isLoading, setIsLoading] = useGlobalState(GlobalStateKey.IS_LOADING)

  let isSpinnerEnabled = props.isLoading ?? isLoading ?? false

  return (
    <React.Fragment>
      <div className={ClassNames("spinner-holder", { "d-none": !isSpinnerEnabled })}>
        <HalfCircleSpinner color="#575757" />
      </div>
      {props.children}
    </React.Fragment>
  )
}

export default Spinner
