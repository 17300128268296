import { nanoid } from "nanoid"
import React, { useState } from "react"
import { Tooltip } from "reactstrap"

export interface InputHelp {
  title: string
  message: string
}

const InputHelpText: React.FC<InputHelp> = props => {
  // local state
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipId, setTooltipId] = useState(nanoid())

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  return (
    <React.Fragment>
      <span
        className={`px-1 text-primary`}
        style={{ fontSize: "9px" }}
        id={`help-${tooltipId}`}
      >
        {props.title}
      </span>
      <Tooltip
        placement="top"
        target={`help-${tooltipId}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
      >
        {props.message}
      </Tooltip>
    </React.Fragment>
  )
}

export default InputHelpText
