import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  Row,
  Col
} from "reactstrap"
import InputText from "../../components/InputText"
import { Image } from "../../../interfaces"
import { StatusCode } from "../../../networking"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import CountryInputSelect from "../../components/inputs/CountryInputSelect"
import { Language } from "../../../language"
import { toast } from "react-hot-toast"
import { updateImageMetaLocation } from "../../../networking/api"

interface Props {
  isModalOpen: boolean
  onModalClose: any
  image: Image | undefined
  onUpdate: any
}

const EditLocationModal: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [image, setImage] = useState<Image | undefined>(undefined)
  const [longitude, setLongitude] = useState<number | undefined>(undefined)
  const [latitude, setLatitude] = useState<number | undefined>(undefined)
  const [country, setCountry] = useState<string | undefined>(undefined)

  // on props update
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)
    setImage(props.image)

    // get image location data
    if (props.image && props.image.locations[0]) {
      let imageMetaLocation = props.image.locations[0]

      setLongitude(imageMetaLocation.longitude)
      setLatitude(imageMetaLocation.latitude)
      setCountry(imageMetaLocation.country)
    }
  }, [props])

  const onLocationUpdate = (country, latitude, longitude) => {
    setCountry(country)
    setLatitude(latitude)
    setLongitude(longitude)
  }

  const onUpdateButtonPressed = () => {
    if (image && image.locations[0].id) {
      const imageMetaLocationId = image.locations[0].id

      updateImageMetaLocation(
        sessionToken,
        imageMetaLocationId,
        latitude,
        longitude,
        country
      ).then(response => {
        if (response && response.status === StatusCode.OK) {
          toast.success(Language.SUCCESS_IMAGE_LOCATION_UPDATED)
          props.onUpdate()
          props.onModalClose()
        }
      })
    }
  }

  return (
    <Modal isOpen={isModalOpen} contentClassName={""} centered>
      <ModalHeader>
        <div className="mb-0">{Language.MT_EDIT_LOCATION}</div>
        <small>{Language.MS_EDIT_LOCATION}</small>
      </ModalHeader>
      <ModalBody>
        {image && (
          <div>
            <Row className="mb-3">
              <Col>
                <InputText
                  type="text"
                  onChange={event => {
                    onLocationUpdate(
                      country,
                      Number(event.target.value),
                      longitude
                    )
                  }}
                  control={{
                    id: "latitude",
                    name: "latitude",
                    caption: "Latitude",
                    placeholder: "Enter latitude",
                    value: latitude
                  }}
                />
              </Col>
              <Col>
                <InputText
                  type="text"
                  onChange={event => {
                    onLocationUpdate(
                      country,
                      latitude,
                      Number(event.target.value)
                    )
                  }}
                  control={{
                    id: "longitude",
                    name: "longitude",
                    caption: "Longitude",
                    placeholder: "Enter longitude",
                    value: longitude
                  }}
                />
              </Col>
            </Row>
            <CountryInputSelect
              onSelect={setCountry}
              selectMessage="Select a country..."
              control={{ value: country, caption: "Country" }}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="m-1 text-white"
            color="secondary"
            onClick={props.onModalClose}
          >
            Close
          </Button>
          <Button
            size="sm"
            className="m-1"
            color="primary"
            onClick={onUpdateButtonPressed}
          >
            Update
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default EditLocationModal
