import { useEffect, useState } from "react"
import InputSelect from "../InputSelect"
import { Control, Species } from "../../../interfaces"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import { StatusCode } from "../../../networking"
import { toPascalCase } from "../../../utility/helpers"
import { getSpecies } from "../../../networking/api"

interface Props {
  control?: Control
  message: string | undefined
  selectedSpecies: string | undefined
  onSelect: any
}

const SpeciesInputSelect: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [availableSpecies, setAvailableSpecies] = useState<Array<Species>>([])
  const [selectedSpecies, setSelectedSpecies] = useState<string | undefined>(
    undefined
  )

  // on props update
  useEffect(() => {
    setSelectedSpecies(props.selectedSpecies)
  }, [props])

  // on load
  useEffect(() => {
    setIsLoading(true)

    getSpecies(sessionToken)
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          let data: Array<any> = []
          response.data.data.forEach(element => {
            data.push({ id: element.name, name: toPascalCase(element.name) })
          })

          if (props.message) {
            data.unshift({ id: undefined, name: props.message })
          }

          setAvailableSpecies(data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const onSelect = (species: string) => {
    setSelectedSpecies(species)
    props.onSelect(species)
  }

  return (
    <InputSelect
      onChange={event => onSelect(event.target.value)}
      control={{ ...props.control, value: selectedSpecies, options: availableSpecies }}
      isDisabled={isLoading}
      className={""}
    />
  )
}

export default SpeciesInputSelect
