import React, { useEffect, useState } from "react"
import { toPascalCase } from "../../../utility/helpers"
import RowEditButton from "../buttons/RowEditButton"
import { Image, ImageMetaSpecies } from "../../../interfaces"
import { Config } from "../../../classes/Config"

interface Props {
  image: Image
  speciesConfidence?: number | undefined
  isEditingEnabled: boolean
  keepOriginalSpecies: boolean
  onEditClicked: any
}

const SpeciesColumn: React.FC<Props> = props => {
  // local state
  const [isEditingEnabled, setIsEditingEnabled] = useState<boolean>(false)

  // on props update
  useEffect(() => {
    setIsEditingEnabled(props.isEditingEnabled)
  }, [props])

  let species: string = "Unknown"

  if (props.image.species.length > 0) {
    const imageMetaSpecies: ImageMetaSpecies = props.image.species[0]

    // has species been reviewed?
    if (imageMetaSpecies.correct_species && !props.keepOriginalSpecies) {
      species = toPascalCase(imageMetaSpecies.correct_species)
    } else if (imageMetaSpecies.species) {
      if (props.keepOriginalSpecies) {
        species = toPascalCase(imageMetaSpecies.species)
      } else if (props.speciesConfidence && props.speciesConfidence > Config.CONFIDENCE_CUT_OFF) {
        species = toPascalCase(imageMetaSpecies.species)
      }
    }
  }

  // if there is no image meta species, then editing should be disabled
  if (isEditingEnabled && !props.image.species.length) {
    setIsEditingEnabled(false)
  }

  return (
    <React.Fragment>
      {species}
      {isEditingEnabled && (
        <div className="float-end d-inline">
          <RowEditButton onClick={props.onEditClicked} />
        </div>
      )}
    </React.Fragment>
  )
}

export default SpeciesColumn
