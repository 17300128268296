export class Config {
  // the minimum amount of images required to run the lbspr analysis
  public static MIN_IMAGES_LBSPR: number = 1
  // if the lbspr modal should warn the user that data restrictions are being ignored
  public static IS_LBSPR_DATA_WARNING_ENABLED: boolean = true
  // if empty lbspr year bins should be considered invalid
  public static HIDE_EMPTY_LBSPR_YEAR_BINS: boolean = true
  // if the LBSPR modal should use mock data (useful for testing)
  public static IS_MOCK_LBSPR_ENABLED: boolean = false
  // the confidence cut off for image species to be considered (unknown will be displayed if confidence does not meet cut off)
  public static CONFIDENCE_CUT_OFF = 70
}
