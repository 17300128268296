import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

interface Props {
  url: string
  iconName: string
  caption: string
  location: any
  iconsOnly: boolean
  onClick?: any
}

const MenuItem: React.FC<Props> = props => {
  // local state
  const [url, setUrl] = useState<string>()
  const [iconName, setIconName] = useState<string>()
  const [caption, setCaption] = useState<string>()
  const [iconsOnly, setIconsOnly] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)

  const location = useLocation()

  // on props update
  useEffect(() => {
    setUrl(props.url)
    setIconName(props.iconName)
    setCaption(props.caption)
    setIconsOnly(props.iconsOnly)
    setIsActive(props.location.pathname === props.url)
  }, [props])

  useEffect(() => {
    setIsActive(location.pathname === url)
  })

  let className = `menu-item`

  if (isActive) className += " menu-item-active"

  const onMenuItemPressed = event => {
    event.preventDefault()
    event.stopPropagation()
    if (props.onClick !== undefined) props.onClick()
  }

  return (
    <React.Fragment>
      {url && (
        <li
          className={className}
          onClick={event => {
            onMenuItemPressed(event)
          }}
        >
          <Link to={url} className="d-flex justify-content-between">
            <div className="m-0">
              <span className={`mt-1 icon icon-${iconName} text-secondary`} />
              {!iconsOnly && <p className="m-0 p-3 d-inline">{caption}</p>}
            </div>
            {isActive && !iconsOnly && (
              <span className="mt-1 icon icon-arrow-right text-secondary" />
            )}
          </Link>
        </li>
      )}
    </React.Fragment>
  )
}

export default MenuItem
