import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useGlobalState } from "../../.."
import { LbsprYearBinCollection } from "../../../classes/LbsprYearBinCollection"
import { LbsprParameterGroup, LbsprYearBin } from "../../../interfaces"
import { ModelEstimates } from "../../../interfaces/lbspr"
import { Language } from "../../../language"
import { StatusCode } from "../../../networking"
import { modelEstimates } from "../../../networking/lbspr"
import { GlobalStateKey } from "../../../utility/globalStateKey"
import ListView from "../ListView"
import Spinner from "../Spinner"
import Box from "../Box"

interface Props {
  lbsprYearBin: LbsprYearBin
  lbsprParameterGroup: LbsprParameterGroup
}

const ResultTable: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [modelledEstimates, setModelledEstimates] =
    useState<Array<ModelEstimates>>()

  useEffect(() => {
    setModelledEstimates([])

    setIsLoading(true)
    modelEstimates(sessionToken, {
      parameters: props.lbsprParameterGroup,
      lengthData: LbsprYearBinCollection.convertToSingleYearLengths(
        props.lbsprYearBin
      )
    })
      .then(response => {
        if (response && response.status === StatusCode.OK) {
          setModelledEstimates([response.data.data])
        } else {
          setModelledEstimates(undefined)
          toast.error(Language.ERR_LBSPR)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [props])

  const headers = [
    { caption: "SPR", field: "SPR", classes: "text-center align-middle" },
    { caption: "SL50", field: "SL50", classes: "text-center align-middle" },
    { caption: "SL95", field: "SL95", classes: "text-center align-middle" },
    { caption: "F/M", field: "FM", classes: "text-center align-middle" }
  ]

  const renderHeader = headers => {
    return headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ))
  }

  const renderRow = (headers, modelEstimates: ModelEstimates) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default:
          return (
            <td key={index} className={header.classes}>
              {modelEstimates[header.field]}
            </td>
          )
      }
    })
    return tableTd
  }

  let bodyRows
  if (modelledEstimates && modelledEstimates.length > 0) {
    bodyRows = modelledEstimates.map((row, index) => (
      <tr key={index}>{renderRow(headers, row)}</tr>
    ))
  }

  // get the appropriate out come message
  let message: string | undefined = undefined
  if (modelledEstimates && modelledEstimates[0]) {
    let modelledEstimate = modelledEstimates[0]
    const spr = modelledEstimate.SPR * 100
    const roundedSpr = Math.round(spr * 100) / 100
    if (roundedSpr > 80) {
      message = Language.LBSPR_OUTCOME_HEALTHY
    } else if (roundedSpr > 40) {
      message = Language.LBSPR_OUTCOME_SUSTAINABLE
    } else if (roundedSpr > 20) {
      message = Language.LBSPR_OUTCOME_OVERFISHED
    } else {
      message = Language.LBSPR_OUTCOME_VERY_OVERFISHED
    }
  }

  return (
    <Spinner isLoading={isLoading}>
      {modelledEstimates && modelledEstimates.length > 0 && (
        <div>
          <ListView
            header={renderHeader(headers)}
            bodyRows={bodyRows}
            rows={modelledEstimates || []}
            iconName={""}
            emptyCaption={Language.I_NO_MODEL_ESTIMATES_AVAILABLE}
          />
          <Box title="Summary:">
            {message}
          </Box>
        </div>
      )}
    </Spinner>
  )
}

export default ResultTable
