import { useEffect, useState } from "react"
import InputSelect from "../InputSelect"
import { supportedCountries, SupportedCountry } from "../../constants/data"
import { Control } from "../../../interfaces"

interface Props {
  selectMessage: string
  onSelect: any
  control?: Control
}

const CountryInputSelect: React.FC<Props> = props => {
  // local state
  const [availableCountries, setAvailableCountries] =
    useState<Array<SupportedCountry>>()

  // on load
  useEffect(() => {
    let countries = [...supportedCountries]
    countries.unshift({ id: undefined, name: props.selectMessage })
    setAvailableCountries(countries)
  }, [])

  const onSelect = (country: string) => {
    let value: string | null = country
    if (country === props.selectMessage) {
      value = null
    }
    props.onSelect(value)
  }

  return (
    <InputSelect
      onChange={event => onSelect(event.target.value)}
      control={{ ...props.control, options: availableCountries }}
      isDisabled={false}
      className=""
    />
  )
}

export default CountryInputSelect
