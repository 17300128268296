export interface SupportedCountry {
  id: string | undefined
  name: string
}

export const supportedCountries: Array<SupportedCountry> = [
  { id: "Australia", name: "Australia" },
  { id: "Papua New Guinea", name: "Papua New Guinea" },
  { id: "Solomon Islands", name: "Solomon Islands" },
  { id: "Fiji", name: "Fiji" },
  { id: "Vanuatu", name: "Vanuatu" },
  { id: "Singapore", name: "Singapore" }
]
