import React, { useState } from "react"
import {
  Card,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Label
} from "reactstrap"

import logo from "../../images/logo.png"
import ocean from "../../images/ocean.jpg"
import { isPasswordValid } from "../../utility/validator"
import { StatusCode } from "../../networking"
import { useParams } from "react-router-dom"
import CurrentTime from "../components/CurrentTime"
import { Language } from "../../language"
import { resetUser } from "../../networking/api"

const ResetPassword: React.FC = () => {
  // local state
  const [password, setPassword] = useState<string>()
  const [confirmPassword, setConfirmPassword] = useState<string>()

  const params = useParams()

  const sectionStyle = {
    backgroundImage: `url(${ocean})`,
    backgroundSize: "cover"
  }

  const onResetPasswordPressed = () => {
    if (params.token && password) {
      resetUser(params.token, password).then(response => {
        if (response && response.status === StatusCode.OK) {
          window.location.replace("/login")
        }
      })
    }
  }

  const isPwdValid = password && isPasswordValid(password)
  const isAbleToProceed =
    password && confirmPassword && password === confirmPassword

  return (
    <div
      className="container-fluid p-0 h-100 bg-primary d-flex flex-column align-items-center"
      style={sectionStyle}
    >
      <div className="d-flex h-100 align-items-center">
        <Card className="login p-4">
          <CardHeader className="text-center">
            <img src={logo} alt="JCUFish" />
          </CardHeader>
          <CardBody>
            {password && !isPwdValid && (
              <small className={"text-danger mb-0"}>
                {Language.ERR_INVALID_PASSWORD_FORMAT}
              </small>
            )}
            <FormGroup>
              <Label for="password" className="mb-0">
                New password
              </Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="New password"
                onChange={event => {
                  setPassword(event.target.value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="cpassword" className="mb-0">
                Confirm new password
              </Label>
              <Input
                type="password"
                id="cpassword"
                name="cpassword"
                value={confirmPassword}
                placeholder="Confirm new password"
                onChange={event => {
                  setConfirmPassword(event.target.value)
                }}
              />
            </FormGroup>
            <Button
              className="bg-secondary text-white mt-3 w-100"
              onClick={onResetPasswordPressed}
              disabled={!isAbleToProceed}
            >
              Reset password
            </Button>
          </CardBody>
          <CardFooter>
            <CurrentTime />
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

export default ResetPassword
