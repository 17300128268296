import React from "react"
import { JSONTree } from "react-json-tree"
import Box from "../Box"

interface Props {
  data: any
}

const theme = {
  scheme: "grayscale",
  author: "alexandre gavioli (https://github.com/alexx2/)",
  base00: "#F2F2F2",
  base01: "#000000",
  base02: "#000000",
  base03: "#000000",
  base04: "#000000",
  base05: "#000000",
  base06: "#000000",
  base07: "#000000",
  base08: "#000000",
  base09: "#000000",
  base0A: "#000000",
  base0B: "#000000",
  base0C: "#000000",
  base0D: "#000000",
  base0E: "#000000",
  base0F: "#000000"
}

const DataTree: React.FC<Props> = props => {
  return (
    <Box title="Length Data:">
      <div className="data-tree">
        <JSONTree
          data={props.data}
          theme={theme}
          invertTheme={false}
          hideRoot={true}
        />
      </div>
    </Box>
  )
}

export default DataTree
