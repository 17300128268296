import ReactDOM from "react-dom"

import App from "./jsx/containers/App"
import { createGlobalState } from "react-hooks-global-state"
import { initialGlobalState } from "./utility/globalState"

const app = document.getElementById("root")

// create and export initial global state
export const { useGlobalState, setGlobalState } =
  createGlobalState(initialGlobalState)

ReactDOM.render(<App />, app)
