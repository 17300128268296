import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap"
import { StatusCode } from "../../../networking"
import { useGlobalState } from "../../.."
import { GlobalStateKey } from "../../../utility/globalStateKey"
import { Image } from "../../../interfaces"
import { Language } from "../../../language"
import { downloadImage } from "../../../networking/api"

interface Props {
  image: Image | undefined
  isModalOpen: boolean
  onModalCloseHandler: any
}

const ImageModal: React.FC<Props> = props => {
  // global state
  const [sessionToken, setSessionToken] = useGlobalState(
    GlobalStateKey.SESSION_TOKEN
  )

  // local state
  const [imageId, setImageId] = useState<string | undefined>(undefined)
  const [imageFile, setImageFile] = useState<string | undefined>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // on props update
  useEffect(() => {
    setImageId(props.image?.id)
    setIsModalOpen(props.isModalOpen)
  }, [props])

  // on image id update
  useEffect(() => {
    if (imageId) {
      downloadImage(sessionToken, imageId).then(response => {
        if (response && response.status === StatusCode.OK) {
          setImageFile(URL.createObjectURL(response.data))
        }
      })
    }
  }, [imageId])

  const onCloseModalPressed = () => {
    setImageFile(undefined)
    props.onModalCloseHandler()
  }

  return (
    <Modal isOpen={isModalOpen} contentClassName={"modal-image"} centered>
      <ModalHeader>
        <div className="mb-0">{Language.MT_VIEW_IMAGE}</div>
      </ModalHeader>
      <ModalBody className="text-center">
        {imageFile && <img alt="Result" src={imageFile} />}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="m-1"
            color="secondary text-white"
            onClick={onCloseModalPressed}
          >
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ImageModal
