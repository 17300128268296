import { UserProfile } from "../interfaces";
import { GlobalStateKey } from "./globalStateKey";

interface GlobalState {
    [GlobalStateKey.SESSION_TOKEN] : undefined | string;
    [GlobalStateKey.IS_LOADING] : boolean;
    [GlobalStateKey.USER_PROFILE]: undefined | UserProfile;
}

export const initialGlobalState: GlobalState = {
    [GlobalStateKey.SESSION_TOKEN]: undefined,
    [GlobalStateKey.IS_LOADING]: false,
    [GlobalStateKey.USER_PROFILE]: undefined
  } 