import moment from "moment"

export function toPascalCase(string) {
  if (!string) return ""

  return string.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase()
  })
}

export function getHumanDateTime(): string {
  return moment().format("DD/MM/YYYY - h:mm A")
}
