import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

import ListView from "../../components/ListView"
import { Language } from "../../../language"

interface Props {
  isModalOpen: boolean
  metaData: any
  onModalCloseHandler: any
}

const MetaModal: React.FC<Props> = props => {
  // local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [metaData, setMetaData] = useState<any>(undefined)

  // on props update
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)
    setMetaData(props.metaData)
  }, [props])

  const onCloseModalPressed = () => {
    props.onModalCloseHandler()
  }

  const headers = [
    { caption: "Key", field: "key", classes: "text-left" },
    { caption: "Value", field: "value", classes: "text-left" }
  ]

  const renderHeader = headers => {
    return headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ))
  }

  const renderRows = (prefix: string, index: string, rows: any) => {
    let renderedRows: Array<any> = []

    for (const key in rows) {
      const value = rows[key]

      if (Array.isArray(value)) {
        renderedRows = renderedRows.concat(
          renderRows(`${prefix}${key}.`, `${index}-${key}`, value)
        )
      } else if (typeof value === "object") {
        renderedRows = renderedRows.concat(
          renderRows(`${prefix}${key}.`, `${index}-${key}`, value)
        )
      } else {
        renderedRows.push(renderRow(index, `${prefix}${key}`, value))
      }
    }

    return renderedRows
  }

  const renderRow = (index: string, key: string, value: any) => {
    return (
      <tr key={`${index}-${key}`}>
        <td key={`0-${index}-${key}`}>{key}</td>
        <td key={`1-${index}-${key}`}>{value}</td>
      </tr>
    )
  }

  let bodyRows
  if (metaData && metaData.length > 0) {
    bodyRows = []
    for (const index in metaData) {
      const row = metaData[index].meta_data
      bodyRows = bodyRows.concat(renderRows("", index, row))
    }
  }

  const iconName = "table"

  return (
    <Modal isOpen={isModalOpen} contentClassName={"modal-image"} centered>
      <ModalHeader>
        <div className="mb-0">{Language.MT_VIEW_META}</div>
      </ModalHeader>
      <ModalBody>
        <ListView
          header={renderHeader(headers)}
          bodyRows={bodyRows}
          rows={metaData || []}
          iconName={iconName}
          emptyCaption="No meta available at this time"
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button
            size="sm"
            className="m-1 text-white"
            color="secondary"
            onClick={onCloseModalPressed}
          >
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default MetaModal
