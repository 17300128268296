import { AxiosResponse } from "axios"
import moment from "moment"
import {
  makeRequest,
  RequestMethod,
  RequestOptions,
  RequestResponseType
} from "."
import { ImageFilterHelper } from "../classes/ImageFilterHelper"
import {
  Authorisation,
  ImageFilter,
  ImagesResponse,
  LbsprLengthsResponse,
  LbsprParameterGroup,
  LbsprParameterGroupFilter,
  LbsprParameterGroupResponse,
  LbsprParameterGroupsResponse,
  ResetUserPasswordResponse,
  SpeciesResponse,
  UserProfileResponse,
  UsersResponse
} from "../interfaces"
import { SessionToken } from "../types"

// Set API from environment
const API_HOST = process.env.REACT_APP_REST_API_HOST ?? "api.jcufish.com.au"
const API_PORT = process.env.REACT_APP_REST_API_PORT
const API_PROTOCOL = window.location.protocol

// Set API URL from environment
let url = `${API_PROTOCOL}//${API_HOST}`
if (API_PORT) url += `:${API_PORT}`

export const API_ENDPOINT = url

export function login(
  username: string,
  password: string
): Promise<AxiosResponse<Authorisation> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/user/login`,
    method: RequestMethod.GET,
    queryParameters: { user: username, pwd: password },
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function getCurrentUser(
  sessionToken: string
): Promise<AxiosResponse<UserProfileResponse> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/profile`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function getSpecies(
  sessionToken: SessionToken
): Promise<AxiosResponse<SpeciesResponse> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/species`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function getUsers(
  sessionToken: SessionToken
): Promise<AxiosResponse<UsersResponse> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/user`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function findLbsprParameterGroup(
  sessionToken: SessionToken,
  filter: LbsprParameterGroupFilter
): Promise<AxiosResponse<LbsprParameterGroupResponse> | undefined> {
  const queryParameters = {}

  // apply result filter
  if (filter) {
    if (filter.species) queryParameters["filter_species"] = filter.species
    if (filter.country) queryParameters["filter_location"] = filter.country
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/lbspr/parameters/find`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    queryParameters: queryParameters,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function getLbsprParameterGroups(
  sessionToken: SessionToken
): Promise<AxiosResponse<LbsprParameterGroupsResponse> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/lbspr/parameters`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function addLbsprParameterGroup(
  sessionToken: SessionToken,
  lbsprParameterGroup: LbsprParameterGroup
): Promise<AxiosResponse<LbsprParameterGroupResponse> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/lbspr/parameters`,
    sessionToken: sessionToken,
    method: RequestMethod.POST,
    responseType: RequestResponseType.NORMAL,
    body: lbsprParameterGroup
  }

  return makeRequest(requestOptions)
}

export function updateLbsprParameterGroup(
  sessionToken: SessionToken,
  lbsprParameterGroup: LbsprParameterGroup
): Promise<AxiosResponse<LbsprParameterGroup> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/lbspr/parameters/${lbsprParameterGroup.id}`,
    sessionToken: sessionToken,
    method: RequestMethod.PUT,
    responseType: RequestResponseType.NORMAL,
    body: lbsprParameterGroup
  }

  return makeRequest(requestOptions)
}

export function deleteLbsprParameterGroup(
  sessionToken: SessionToken,
  lbsprParameterGroupId: string
) {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/lbspr/parameters/${lbsprParameterGroupId}`,
    sessionToken: sessionToken,
    method: RequestMethod.DELETE,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function defaultLbsprParameterGroup(
  sessionToken: SessionToken,
  lbsprParameterGroup: LbsprParameterGroup,
  isDefault: boolean
): Promise<AxiosResponse<LbsprParameterGroup> | undefined> {
  const requestBody = {
    default: isDefault
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/lbspr/parameters/${lbsprParameterGroup.id}/default`,
    sessionToken: sessionToken,
    method: RequestMethod.PATCH,
    responseType: RequestResponseType.NORMAL,
    body: requestBody
  }

  return makeRequest(requestOptions)
}

export function addUser(
  sessionToken: SessionToken,
  emailAddress: string,
  password: string
) {
  const requestBody = {
    email: emailAddress,
    pwd: password
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/user`,
    sessionToken: sessionToken,
    method: RequestMethod.POST,
    responseType: RequestResponseType.NORMAL,
    body: requestBody
  }

  return makeRequest(requestOptions)
}

export function updateUser(resetToken: string, newPassword: string) {}

export function getUserReset(
  sessionToken: SessionToken,
  userId: string
): Promise<AxiosResponse<ResetUserPasswordResponse> | undefined> {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/user/${userId}/reset`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function resetUser(resetToken: string, newPassword: string) {
  const requestBody = {
    pwd: newPassword
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/user/reset/${resetToken}`,
    method: RequestMethod.PUT,
    responseType: RequestResponseType.NORMAL,
    body: requestBody
  }

  return makeRequest(requestOptions)
}

export function getImages(
  sessionToken: SessionToken,
  page: number,
  size: number,
  filter: ImageFilter | undefined
): Promise<AxiosResponse<ImagesResponse> | undefined> {
  let queryParameters = {
    page: page,
    size: size
  }

  // apply result filter
  const imageFilterObject = filter ? ImageFilterHelper.convertToQueryParameters(filter) : {}
  if (filter) queryParameters = { ...queryParameters, ...imageFilterObject }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/images`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    queryParameters: queryParameters,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function createSpecies(sessionToken: SessionToken, speciesName: string) {
  const requestBody = {
    name: speciesName
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/species`,
    sessionToken: sessionToken,
    method: RequestMethod.POST,
    responseType: RequestResponseType.NORMAL,
    body: requestBody
  }

  return makeRequest(requestOptions)
}

export function updateImageMetaSpecies(
  sessionToken: SessionToken,
  imageMetaSpeciesId: string,
  correctSpecies: string
) {
  const requestBody = {
    id: imageMetaSpeciesId,
    correct_species: correctSpecies,
    review_time: moment().format("YYYY-MMM-DD hh:mm A")
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/images/species/${imageMetaSpeciesId}`,
    sessionToken: sessionToken,
    method: RequestMethod.PUT,
    responseType: RequestResponseType.NORMAL,
    body: requestBody
  }

  return makeRequest(requestOptions)
}

export function updateImageMetaLocation(
  sessionToken: SessionToken,
  imageMetaLocationId: string,
  latitude: number | undefined,
  longitude: number | undefined,
  country: string | undefined
) {
  const requestBody = {
    id: imageMetaLocationId,
    latitude: latitude,
    longitude: longitude,
    country: country
  }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/images/location/${imageMetaLocationId}`,
    sessionToken: sessionToken,
    method: RequestMethod.PUT,
    responseType: RequestResponseType.NORMAL,
    body: requestBody
  }

  return makeRequest(requestOptions)
}

export function deleteImage(sessionToken: SessionToken, imageId: string) {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/images/${imageId}`,
    sessionToken: sessionToken,
    method: RequestMethod.DELETE,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function downloadImage(sessionToken: SessionToken, imageId: string) {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/images/${imageId}/download/full`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.BLOB
  }

  return makeRequest(requestOptions)
}

export function downloadImageThumbnail(
  sessionToken: SessionToken,
  imageId: string
) {
  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/images/${imageId}/download/small?session-token=${sessionToken}`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    responseType: RequestResponseType.BLOB
  }

  return makeRequest(requestOptions)
}

export function getLbsprLengths(
  sessionToken: SessionToken,
  filter: ImageFilter
): Promise<AxiosResponse<LbsprLengthsResponse> | undefined> {
  let queryParameters = {}

  if (!ImageFilterHelper.isValidForLbsprLengths(filter)) {
    return new Promise(() => {})
  }

  // apply result filter
  const imageFilterObject = filter ? ImageFilterHelper.convertToQueryParameters(filter) : {}
  if (filter) queryParameters = { ...queryParameters, ...imageFilterObject }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/lbspr/lengths`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    queryParameters: queryParameters,
    responseType: RequestResponseType.NORMAL
  }

  return makeRequest(requestOptions)
}

export function downloadLBSPR(
  sessionToken: SessionToken,
  filter: ImageFilter | undefined
): Promise<AxiosResponse<any> | undefined> {
  let queryParameters = {}

  // apply result filter
  const imageFilterObject = filter ? ImageFilterHelper.convertToQueryParameters(filter) : {}
  if (filter) queryParameters = { ...queryParameters, ...imageFilterObject }

  const requestOptions: RequestOptions = {
    url: `${API_ENDPOINT}/irs/lbspr/download`,
    sessionToken: sessionToken,
    method: RequestMethod.GET,
    queryParameters: queryParameters,
    responseType: RequestResponseType.BLOB
  }

  return makeRequest(requestOptions)
}
